import React, { Component } from "react";

import part1 from '../../config/customer/part1.json';

import './index.scss';

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data1: part1.data1,
      data2: part1.data2
    }
  }
  renderImg() {
    const { data1 } = this.state;
    return data1.map((item, i) => (
      <section key={i} className="col-xs-3 list">
        <section className="each-list">
          <div className="top-line"></div>
          <div className="left-line"></div>
          <div className="bottom-line"></div>
          <div className="right-line"></div>
          <div className="each-list-div">
            <img src={item.img} />
            <span className="title">{item.title}</span>
            <span className="content">{item.content}</span>
          </div>
        </section>
      </section>
    ))
  }
  renderData2() {
    const { data2 } = this.state;
    return data2.map((item, i) => (
      <section key={i} className="part-2 col-xs-12">
        <p className="text-center p-1">{item.title}</p>
        <p className="p-2"></p>
        {
          item.content.map((list, k) => (
            <section key={k} className="col-xs-4 main">
              <div className="each-main">
                <div className="top-line"></div>
                <div className="left-line"></div>
                <div className="bottom-line"></div>
                <div className="right-line"></div>
                <p className="p-3">{list.name}</p>
                <p className="p-4"></p>
                <p className="p-5">{list.descr}</p>
              </div>
            </section>
          ))
        }
      </section>
    ))
  }
  render() {
    return (
      <section className="customer">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/customer/banner@2x.png'} />
          {/* banner图文字 */}
          <div className="content">
            <p className="p-1 fade-in-left">宇中科技提供行业解决方案</p>
            <p className="p-2 fade-in-left">助力产业化数字升级，让天下商家更爽快</p>
          </div>
        </section>
        <section className="main container">
          <section className="part-1 col-xs-12 container">
            {this.renderImg()}
          </section>
          {this.renderData2()}
        </section>
      </section>
    )
  }
}

export default Customer;
