import React, { Component } from 'react';
import Swiper from "swiper";
import "swiper/css/swiper.css";

import part1 from '../../../config/more/join/part1.json'

import './index.scss';

class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: part1.position2
    }
  }
  renderPosition() {
    const { position } = this.state;
    return position.map(item => (
      <section key={item.id} className="list swiper-slide col-xs-3">
        <div className="position">
          <div className="top-line"></div>
          <div className="left-line"></div>
          <div className="bottom-line"></div>
          <div className="right-line"></div>
          <img src={item.img} />
          <p className="p-3"><span>{item.position}</span><span>{item.position2}</span></p>
          <p className="p-4"><span>{item.experience}</span><span className="line">|</span><span>{item.education}</span></p>
          <p className="p-5">广州天河区</p>
        </div>
      </section>
    ))
  }
  componentDidMount() {
    new Swiper('.swiper-container', {
      slidesPerView: 4,
      spaceBetween: 10,
      slidesPerGroup: 4,
      loopFillGroupWithBlank: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
  render() {
    return (
      <section className="join">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/more/banner4.png'} />
          {/* 嵌入banner图 */}
          <section className="col-xs-12 insert-banner">
            <img src={'img/more/yinhao.png'} />
            <p className="p-1">应聘简历可发送至宇中邮箱：info@unizone.tech，请在邮件标题注明申请岗位</p>
            <p className="p-2">加入宇中大家庭</p>
            {/* <div>
              <select className="form-control">
                {
                  part1.position.map((item, i) => (
                    <option key={i}>{item}</option>
                  ))
                }
              </select>
              <button type="submit" className="btn btn-danger"><a target="_blank" href="https://jobs.51job.com/all/co3759079.html">前程入口</a></button>
              <button type="submit" className="btn btn-danger"><a target="_blank" href="https://company.zhaopin.com/CZ310919780.htm?srccode=401901&preactionid=3db7a6fc-595e-4a95-8310-90c787481583">智联入口</a></button>
            </div> */}
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {this.renderPosition()}
              </div>
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </section>
        </section>
      </section>
    )
  }
}

export default Join;
