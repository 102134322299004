import React from 'react';
import {withRouter} from 'react-router-dom';
import moreCoupon from '../../../config/coupon/moreCoupon.json';
import './index.scss';

function MoreCoupon(props) {
  const toDetail = (id) => {
    props.history.push(`couponDetail?id=${id}`);
  }
  
  return (
    <div className="more-coupon">
      <ul>
        {
          moreCoupon.map((item, i) => (
            <li key={i} onClick={ () => toDetail(item.id) }>
              <span className={`sort  ${i < 3 ? 'sort1' : ''}`}>{ i + 1 }</span>
              <span>
              { item.title }
            </span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default withRouter(MoreCoupon);
