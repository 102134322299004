import React, { Component } from "react";
import { getOffsetTop } from '../../../utils/common-utils';
import Swiper from "swiper";
import "swiper/css/swiper.css";

import part1 from '../../../config/more/introduce/part1.json';
import part2 from '../../../config/more/introduce/part2.json';

import './index.scss';

class Introduce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data1: part1.content,
      data2: part2.imgList
    }
  }
  renderImg() {
    const { data1 } = this.state;
    return data1.map((item, i) => (
      <section key={i} className="col-xs-3 list">
        <section className="each-list">
          <div className="top-line"></div>
          <div className="left-line"></div>
          <div className="bottom-line"></div>
          <div className="right-line"></div>
          <div className="each-list-div">
            <img src={item.img} />
            <span className="title">{item.title}</span>
            <span className="descr">{item.descr}</span>
            <span className="content">{item.content}</span>
          </div>
        </section>
      </section>
    ))
  }
  renderData2() {
    const { data2 } = this.state;
    return data2.map((item, i) => (
      <div key={i} className="swiper-slide">
        <img src={item} />
      </div>
    ))
  }
  handleScroll() {
    var window_height = document.documentElement.clientHeight;
    window.onresize = function () { window_height = document.documentElement.clientHeight; };
    var leftEleven = document.getElementsByClassName('left-content')[0];
    var rightEleven = document.getElementsByClassName('right-content')[0];
    var _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    if (leftEleven.classList.contains("fade-in-left") === false && _scrollTop >= getOffsetTop(leftEleven) - window_height && _scrollTop <= getOffsetTop(leftEleven)) {
      leftEleven.classList.add("fade-in-left");
    }
    if (rightEleven.classList.contains("fade-in-right") === false && _scrollTop >= getOffsetTop(rightEleven) - window_height && _scrollTop <= getOffsetTop(rightEleven)) {
      rightEleven.classList.add("fade-in-right");
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    new Swiper('.swiper-container', {
      slidesPerView: 5,
      spaceBetween: 50,
      slidesPerGroup: 5,
      loopFillGroupWithBlank: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }
  render() {
    return (
      <section className="introduce">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/more/banner.png'} />
          {/* banner图文字 */}
          <div className="content">
            <p className="p-3 fade-in-left"></p>
            <p className="p-1 fade-in-left">智云寰宇，中领未来</p>
            <p className="p-2 fade-in-left">宇中科技创新开发智慧云服务，助力产业升级引领未来</p>
            <span className="fade-in-left">智慧生态云</span>
          </div>
        </section>
        <section className="main container">
          <div className="part-1 container">
            <p className="p-1" style={{ marginBottom: "5px" }}>关于宇中</p>
            <p className="p-2">About Unizone</p>
            <p className="p-line"></p>
            <div className="about col-xs-11 container">
              <div className="left-content col-xs-6">
                &nbsp;&nbsp;&nbsp;&nbsp;广州宇中网络科技有限公司成立于2015年，作为全国领先的产业生态云服务商。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;宇中科技自主研发“智慧生态云”平台，提供智慧门店应用、大数据运营、渠道分销系统、数字化中台、人工智能等互联网生态云应用的开发、运营和推广服务。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;宇中科技，广东省高新技术企业，中国电信、蚂蚁金服、腾讯微信支付的全国行业应用核心服务商，专注为通信行业、房地产行业及泛消费行业企业提供“新零售”、“生态营销”、“私域流量运营”的整体解决方案。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;公司总部设在广州，分别在北京、杭州设有分公司，已与通信运营商、房地产行业、文化旅游行业等800多家大中型企业客户建立合作关系。<br />
              </div>
              <div className="right-content col-xs-6">
                <img className="r-img" src={'img/more/dasha.png'} />
              </div>
            </div>
          </div>
          <div className="part-2 container">
            <p className="p-1">{part1.title.p1}</p>
            <p className="p-2">{part1.title.p2}</p>
            <p className="p-line"></p>
            {this.renderImg()}
          </div>
          <div className="part-3 container">
            <p className="p-1">{part2.title.p1}</p>
            <p className="p-2">{part2.title.p2}</p>
            <p className="p-line"></p>
            <section className="img swiper-container">
              <div className="swiper-wrapper">
                {this.renderData2()}
              </div>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
            </section>
          </div>
        </section>
      </section>
    )
  }
}

export default Introduce;
