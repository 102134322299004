import React, { Component } from 'react';
import Nav from '../../components/nav';
import Footer from '../../components/footer';
import './index.scss';

class Index extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="index">
        <Nav></Nav>
        {this.props.children}
        <Footer></Footer>
      </div>
    )
  }
}

export default Index;
