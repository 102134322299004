function getOffsetTop(ele) {
  var rtn = ele.offsetTop;
  var o = ele.offsetParent;
  while (o != null) {
    rtn += o.offsetTop;
    o = o.offsetParent;
  }
  return rtn;
}

module.exports = {
  getOffsetTop
}
