import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import filter from '../../../config/navigate/filter.json';
import shopList from '../../../config/navigate/shopList.json';
import NavigateList from '../../../components/navigate/list';

class Navigate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortIndex: -1,
      typeIndex: -1,
      letterIndex: -1,
      shopItem: shopList
    }
  }
  renderFilter() {
    return (
      <div className="tabBar">
        <div className="sort">
          <p>排序：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderSort()
              }
            </ul>
          </div>
        </div>
        <div className="sort type">
          <p>类型：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderType()
              }
            </ul>
          </div>
        </div>
        <div className="sort type letter">
          <p>字母：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              { this.renderLetter() }
            </ul>
          </div>
        </div>
      </div>
    )
  }
  renderList() {
    const { shopItem } = this.state;
    return (
      <div className="list-tab notTab">
        <div className="ivu-tabs ivu-tabs-no-animation">
          <div className="ivu-tabs-content" style={{ "transform": "translateX(0%) translateZ(0px)" }}>
            <div className="ivu-tabs-tabpane">
              <div className="popular">
                {
                  shopItem.length ?
                    <div className="right">
                      <ul className="navigate-li">
                        { this.renderListItem() }
                      </ul>
                    </div>
                    :
                    <div className="none">
                      <div className="none-item"/>
                      <p>暂无数据</p>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderSort() { // 排序
    const { sortIndex } = this.state;
    const { sort } = filter;
    let html = '';
    html = sort.map((item, i) => (
      <li className={ sortIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'sort')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderType() { // 类型
    const { typeIndex } = this.state;
    const { type } = filter;
    let html = '';
    html = type.map((item, i) => (
      <li className={ typeIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'type')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderLetter() { // 字母
    const { letterIndex } = this.state;
    const { letter } = filter;
    let html = '';
    html = letter.map((item, i) => (
      <li className={ letterIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'letter')}>
        { item.type }
      </li>
    ))
    return html;
  }
  filter(condition, data) { // 条件过滤
    return data.filter(item => {
      return Object.keys(condition).every(key => {
        if (key) {
          return String(item[key]).toLowerCase().includes(
            String(condition[key]).trim().toLowerCase()
          )
        }
      })
    })
  }
  renderActive(index, type) { // 点击过滤信息
    this.setState({
      [`${type}Index`]: index
    }, () => {
      const { sortIndex, typeIndex, letterIndex } = this.state;
      const { letter, sort } = filter;
      const type1 = filter.type;
      if (sort[sortIndex] && sort[sortIndex].type === 'time') {
        this.setState({
          shopItem: shopList.sort((a, b) => {
            return a.time - b.time;
          })
        })
      } else if (sort[sortIndex] && sort[sortIndex].type === 'hot') {
        this.setState(() => {
          shopItem: shopList.sort((a, b) => {
            return a.hot - b.hot;
          })
        })
      }
      // 索引1为字母搜索，索引2为类型搜索
      let temp;
      temp =  {
        type: ((type1[typeIndex] || {}).type === 'all' ? '' : (type1[typeIndex] || {}).type) || '',
        letter:  ((letter[letterIndex] || {}).type === '全部' ? '' : (letter[letterIndex] || {}).type) || ''
      };
      this.setState({
        shopItem: this.filter(temp, shopList)
      })
    })
  }
  renderListItem() {
    let html = '';
    const { shopItem } = this.state;
    shopItem.forEach(item => {
      item.time = Math.floor(Math.random() * 1000);
      item.hot = Math.floor(Math.random() * 1000);
    })
    if (shopItem.length) {
      html = shopItem.map((item, i) => (
        <NavigateList key={i} data={item}/>
      ))
    }
    return html;
  }
  render() {
    return (
      <div className="tab-content">
        { this.renderFilter() }
        { this.renderList() }
      </div>
    )
  }
}

export default withRouter(Navigate);
