import {Modal, Button, Form, Input, message, Col, Row} from 'antd';
import React, {useState} from 'react';
import './index.scss';
import axios from 'axios';

function LoginModal(props) {
  let {isModalVisible, showLogin} = props;
  const [isRegister, setIsRegister] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [verifyImg, setVerifyImg] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const baseUrl = 'http://shuangkuai.co/api/';
  const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
  const loginClosable = true;
  const [form] = Form.useForm();
  
  const getCaptcha = (phone) => {
    axios.post(`${baseUrl}/captcha/create`, {
      phone
    }).then(res => {
      const data = res.data || {};
      setVerifyImg(data.captchaBase64);
    })
  }
  
  const changeCaptcha = () => {
    getCaptcha(phone);
  }
  
  const changePhone = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      setPhone(phone);
      setIsVerify(!isVerify);
      getCaptcha(phone);
    } else {
      setIsVerify(false);
    }
  }
  
  const handleOk = () => {
    showLogin();
  };
  
  const handleCancel = () => {
    form.resetFields();
    setIsVerify(false);
    setIsRegister(false);
    showLogin();
  };
  
  const setLoading = () => {
    setLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = false;
      return newLoadings;
    });
  }
  
  const setSessionStorage = () => {
    const originalSetItem = sessionStorage.setItem;
    sessionStorage.setItem =  function(key, newValue) {
      const setItemEvent = new Event('setItemEvent');
      setItemEvent.key = key;
      setItemEvent.newValue = newValue;
      setItemEvent.oldValue = sessionStorage.getItem(key);
      window.dispatchEvent(setItemEvent);
      originalSetItem.apply(this, arguments);
    }
  }
  
  const onFinish = (values) => {
    const { phone, userpwd, username, captchaCode, userpwdCheck } = values;
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = true;
      return newLoadings;
    })
    axios.post(`${baseUrl}user/${isRegister ? 'register' : 'login'}`, {
      phone,
      userpwd,
      username,
      captchaCode,
      userpwdCheck : isRegister ? userpwdCheck : undefined
    }).then(res => {
      const data = res.data;
      const head = data.head || {};
      const { code, subMsg } = head || {};
      if (code === "10001") {
        setTimeout(() => {
          setLoading();
          message.error(subMsg);
        }, 1000)
      } else if (isRegister && data.userid) {
        setTimeout(() => {
          setLoading();
          message.success('注册成功');
          setIsVerify(false);
          setIsRegister(false);
          form.resetFields();
        }, 1000)
      } else if (!isRegister && data.userid) {
        setTimeout(() => {
          setSessionStorage();
          sessionStorage.setItem("ADMIN", JSON.stringify({
            userid: data.userid,
            username: data.username
          }))
          setLoading();
          message.success('登录成功');
          setIsVerify(false);
          setIsRegister(false);
          form.resetFields();
          showLogin();
        }, 1000)
      }
    })
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  const onRegister = () => {
    setIsRegister(!isRegister);
    form.resetFields();
  }
  
  return (
    <div>
      <Modal
          title={ isRegister ? '注册' : '登录' }
          visible={isModalVisible}
          footer={[]}
          maskClosable={false}
          closable={loginClosable}
          onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="手机号"
            name="phone"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号码格式！'
              },
              {
              
              }
            ]}
          >
            <Input maxLength={11} placeholder="请输入" ref="phone" onChange={changePhone}/>
          </Form.Item>
          
          <Form.Item
            label="账号"
            name="username"
            rules={[
              {
                required: true,
                message: '请输入账号',
              },
              {
                pattern: !pattern,
                message: '请输入正确的账号格式！'
              }
            ]}
            extra="填写4-6个字符，一个汉字为两个字符"
          >
            <Input placeholder="请输入" ref="username"/>
          </Form.Item>
          
          <Form.Item
            label="密码"
            name="userpwd"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}
            hasFeedback
            extra="为了您的账号安全，建议密码设置为6个字符以上"
          >
            <Input.Password ref="pwd"/>
          </Form.Item>
  
          {
            isRegister ?
              <Form.Item
                name="userpwdCheck"
                label="确认密码"
                dependencies={['userpwd']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: '请确认您的密码',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('userpwd') === value) {
                        return Promise.resolve();
                      }
          
                      return Promise.reject(new Error('您输入的两个密码不匹配'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              : null
          }
  
          {
            isVerify ?
              <Form.Item label="图形验证码" extra="请输入下面验证码上的字母或数字">
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="captchaCode"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: '请输入您得到的验证码'
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <img className="verify-img" src={verifyImg} onClick={changeCaptcha} alt=""/>
                  </Col>
                </Row>
              </Form.Item>
              : null
          }
          
          <Form.Item
            wrapperCol={{
              offset: 13,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" loading={loadings[0]}>
              {
                isRegister ? '注册' : '登录'
              }
            </Button>
            {
              !isRegister ?
              <Button type="link" htmlType="button" onClick={onRegister}>
                立即注册
              </Button>
              :
              <Button type="link" htmlType="button" onClick={onRegister}>
                立即登录
              </Button>
            }
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default LoginModal;
