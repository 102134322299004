import {useState} from 'react';

export function adminInfo() {
  let [admin, setAdmin] = useState(JSON.parse(sessionStorage.getItem(("ADMIN") || {})));
  window.addEventListener("setItemEvent", (e) => {
    setAdmin(() => {
      return JSON.parse(e.newValue)
    })
  });
  return admin;
}
