import React, {useState} from 'react';
import {Modal, Button, Form, Input, message, DatePicker} from 'antd';
const { RangePicker } = DatePicker;

function publishCoupon(props) {
  const { isPublishVisible, showPublish } = props;
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState([]);
  const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
  const publishClosable = true;
  
  const handleCancel = () => {
    form.resetFields();
  }
  
  const setLoading = () => {
    setLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = false;
      return newLoadings;
    });
  }
  
  const onFinish = () => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = true;
      return newLoadings;
    })
    setTimeout(() => {
      setLoading();
      message.success('提交成功');
      form.resetFields();
      showPublish();
    }, 1000)
  }
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <div className="publish-modal">
      <Modal
        title={ '发布优惠券' }
        visible={isPublishVisible}
        footer={[]}
        maskClosable={false}
        closable={false}
        onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="优惠券名称"
            name="name"
            rules={[
              {
                required: true,
                message: '请输入优惠券名称'
              },
              {
                pattern: !pattern,
                message: '请输入正确的格式！'
              }
            ]}
          >
            <Input placeholder="请输入优惠券名称" ref="name"/>
          </Form.Item>
  
          <Form.Item
            label="优惠券有效期"
            name="dateRange"
            rules={[
              {
                required: true,
                message: '请选择优惠券有效期'
              }
            ]}>
            <RangePicker placeholder={['请选择开始时间', '请选择结束时间']}/>
          </Form.Item>
          
          <Form.Item
            label="优惠券限制"
            name="limit"
            rules={[
              {
                required: true,
                message: '请输入优惠券限制'
              },
              {
                pattern: !pattern,
                message: '请输入正确的格式！'
              }
            ]}>
            <Input placeholder="请输入优惠券限制" ref="limit" />
          </Form.Item>
  
          <Form.Item
            label="使用条件"
            name="condition"
            rules={[
              {
                required: true,
                message: '请输入使用条件'
              },
              {
                pattern: !pattern,
                message: '请输入正确的格式！'
              }
            ]}
          >
            <Input placeholder="请输入使用条件" ref="condition"/>
          </Form.Item>
  
          <Form.Item
            wrapperCol={{
              offset: 15,
              span: 16,
            }}
          >
            <Button onClick={showPublish} style={{ 'marginRight': '15px' }}>取消</Button>
            <Button type="primary" htmlType="submit" loading={loadings[0]}>提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default publishCoupon;
