import React, {useState} from 'react';
import { Button } from 'antd';
import './index.scss';

function businessItem(props) {
  const { data } = props;
  
  const toDetail = (link) => {
    window.open(link);
  }
  
  return (
    <div className="business-item">
      <p>商家信息</p>
      <div className="business-content">
        <img src={data.logo} alt=""/>
        <div>
          <span>商家名称：{ data.name }</span>
          <span>主营项目：{ data.project }</span>
        </div>
      </div>
      <Button type="primary" onClick={ () => toDetail(data.link) }>去商家购物</Button>
    </div>
  )
}

export default businessItem;
