import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route, Switch
} from 'react-router-dom';

import App from '../../App';
import AppIndex from '../../modules/index';
import Home from '../../modules/home';
import Middle from '../../modules/solution/middle';
import Minishop from '../../modules/solution/minishop';
import Ecology from '../../modules/solution/ecology';
import Finance from '../../modules/solution/finance';
import Customer from '../../modules/customer';
import Introduce from '../../modules/more/introduce';
import Contact from '../../modules/more/contact';
import Join from '../../modules/more/join';
import Navigate from '../../modules/navigate/list';
import NavigateDetail from '../../modules/navigate/detail';
import Boutique from '../../modules/boutique/list';
import BoutiqueDetail from '../../modules/boutique/detail';
import ArticleDetail from '../../modules/navigate/article';
import TagDetail from '../../modules/boutique/tag';
import Coupon from '../../modules/coupon/home';
import CouponDetail from '../../modules/coupon/detail';
import CouponCommodity from '../../modules/coupon/commodity';
import CommodityDetail from '../../modules/coupon/commodityDetail';
import AllCoupon from '../../modules/coupon/allCoupon';
import DetailInner from '../../modules/coupon/detailInner';

export default class extends Component {
  render() {
    return (
      <Router>
        <App>
          <Switch>
            <Route path="/" render={() => (
              <AppIndex>
                <Route exact path="/" component={Coupon}/>
                {/*<Route exact path="/" component={Navigate}/>*/}
                <Route exact path="/content" component={Home}/>
                <Route exact path="/middle" component={Middle}/>
                <Route exact path="/minishop" component={Minishop}/>
                <Route exact path="/ecology" component={Ecology}/>
                <Route path="/finance" component={Finance}/>
                <Route path="/customer" component={Customer}/>
                <Route path="/introduce" component={Introduce}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/join" component={Join}/>
                <Route path="/navigate" component={Navigate}/>
                <Route path="/navigateDetail" component={NavigateDetail}/>
                <Route path="/boutique" component={Boutique}/>
                <Route path="/boutiqueDetail" component={BoutiqueDetail}/>
                <Route path="/articleDetail" component={ArticleDetail}/>
                <Route path="/tagDetail" component={TagDetail}/>
                <Route path="/coupon" component={Coupon}/>
                <Route path="/couponDetail" component={CouponDetail}/>
                <Route path="/couponCommodity" component={CouponCommodity}/>
                <Route path="/commodityDetail" component={CommodityDetail} />
                <Route path="/allCoupon" component={AllCoupon} />
                <Route path="/detailInner" component={DetailInner} />
              </AppIndex>
            )}>
            </Route>
          </Switch>
        </App>
      </Router>
    )
  }
}
