import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import { adminInfo } from '../../../utils/admin-utils';
import './index.scss';

function NavigateDetailItem(props) {
  const { data } = props;
  const admin = adminInfo() || {};
  
  const onJump = (link) => {
    window.open(link)
  }
  
  return (
    <div className="navigate-detail-item">
      <div className="boutique">
        <div className="externalCoupons">
          <div className="pic">
            <img src={`${data.logo}`} alt=""/>
          </div>
          <div className="info">
            <p className="title">
              {
                data.subTitle ?
                  <span className="subTitle">
                    { data.subTitle }
                  </span>
                : null
              }
              {
                data.title ?
                  <span className="titles">
                    { data.title }
                  </span>
                : null
              }
            </p>
            <div className="discount">
              <p>
                <span className="couponPrice">
                  <span>{ data.secondTitle }</span>
                </span>
              </p>
            </div>
            <div className="template" dangerouslySetInnerHTML={{__html: data.content}}/>
            <p className="right">
              { data.name }
              {
                (admin || {}).username ?
                  <span className="receiveButton" onClick={() => onJump(data.link)}>
                    领取优惠码
                  </span>
                  :
                  null
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(NavigateDetailItem);
