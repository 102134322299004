import React, { Component } from "react";
import './index.scss';
import { withRouter } from 'react-router-dom';

function RightBoxItem(props) {
  const { data } = props;
  const toDetail = (id,type) => {
    if (type === 'couponDetail') {
      props.history.push(`/couponDetail?id=${id}`);
    } else if (type === 'commodityDetail') {
      props.history.push(`/commodityDetail?id=${id}`);
    }
  };
  return (
    <li className="right-box-item">
      <img src={ data.logo } alt=""/>
      <p className="hot-info">
        <span className="hot-info-tit" onClick={() => toDetail(data.id,data.type) }>
          <span>
            { data.title }
          </span>
        </span>
        <span className="hot-info-extra">
          <span className="extra-item">
            所属商城：{ data.coupon }
          </span>
        </span>
      </p>
    </li>
  )
}

export default withRouter(RightBoxItem);
