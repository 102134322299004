import React, { Component } from 'react';

import part1 from '../../../config/solution/middle/part1.json';
import part2 from '../../../config/solution/middle/part2.json';
import part3 from '../../../config/solution/middle/part3.json';

import './index.scss';

class Middle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: part3[1].content[0].user,
      business: part3[1].content[1].business,
      data1: part3[1].content[2].data1,
      data2: part3[1].content[3].data2.content,
      data3: part3[1].content[4].data3,
      data4: part3[1].content[5].data4,
      data5: part3[1].content[6].data5.content,
      backstage: part3[1].content[7].data6
    }
  }
  renderUser() {
    const { user } = this.state;
    return user.map((item, i) => (
      <div key={i} className="col-xs-2 each-user">
        <span>{item}</span>
      </div>
    ))
  }
  renderBusiness() {
    const { business } = this.state;
    return business.map((item, i) => (
      <ul key={i} className="col-xs-1 each-business">
        <li><span>{item.first}</span></li>
        <li><span>{item.second}</span></li>
        <li><span>{item.third}</span></li>
        <li><span>{item.fourth}</span></li>
        <li><span>{item.fifth}</span></li>
        <li><span>{item.sixth}</span></li>
        <li><span>{item.seventh}</span></li>
      </ul>
    ))
  }
  renderData1() {
    const { data1 } = this.state;
    return data1.map((item, i) => (
      <ul key={i} className="col-xs-2 each-data">
        <li>{item.first}</li>
        <li>{item.second}</li>
        <li>{item.third}</li>
        <li>{item.fourth}</li>
        <li>{item.fifth}</li>
        <li>{item.sixth}</li>
      </ul>
    ))
  }
  renderData2() {
    const { data2 } = this.state;
    return (
      <div className="data-2 col-xs-4">
        <p className="p-1">面向应用及开发，统一数据服务中间件</p>
        <ul className="center">
          {
            data2.map((item, i) => (
              <li key={i}>{item}</li>
            ))
          }
        </ul>
        <p className="p-1">采集/接入/爬取/存储</p>
      </div>
    )
  }
  renderData3() {
    const { data3 } = this.state;
    return data3.map((item, i) => (
      <ul key={i} className="col-xs-2 each-data">
        <li>{item.first}</li>
        <li>{item.second}</li>
        <li>{item.third}</li>
        <li>{item.fourth}</li>
        <li>{item.fifth}</li>
        <li>{item.sixth}</li>
      </ul>
    ))
  }
  renderData4() {
    const { data4 } = this.state;
    return (
      <div className="data-4 col-xs-4">
        <p className="p-1">技术基础层</p>
        <ul className="center">
          {
            data4.map((item, i) => (
              <li key={i}>{item}</li>
            ))
          }
        </ul>
      </div>
    )
  }
  renderData5() {
    const { data5 } = this.state;
    return data5.map((item, i) => (
      <section key={i} className="col-xs-4 main">
        <div className="each-main">
          <div className="top-line"></div>
          <div className="left-line"></div>
          <div className="bottom-line"></div>
          <div className="right-line"></div>
          <p className="p-3">{item.title}</p>
          <p className="p-4"></p>
          <p className="p-5">{item.content}</p>
        </div>
      </section>
    ))
  }
  renderBackStage() {
    const { backstage } = this.state;
    return backstage.map((item, i) => (
      <div key={i} className="col-xs-3">
        <div key={i} className="col-xs-12 each-stage">
          <span>{item}</span>
        </div>
      </div>
    ))
  }
  render() {
    return (
      <div className="middle">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/solution/banner@2x.png'} />
          {/* banner图文字 */}
          <div className="content">
            <p className="p-1 fade-in-down">{part1[0].p1}</p>
            <p className="p-2 fade-in-down">{part1[0].p2}</p>
            <div className="img-list col-xs-8 fade-in-up">
              {
                part1[1].imgList.map((item, index) => {
                  return (
                    <img key={index} src={item} />
                  )
                })
              }
            </div>
          </div>
        </section>
        <section className="main container">
          <section className="part-1 col-xs-12">
            {
              part2.list.map((item, index) => {
                return (
                  <div key={index} className="each-main">
                    <div className="top-line"></div>
                    <div className="left-line"></div>
                    <div className="bottom-line"></div>
                    <div className="right-line"></div>
                    <img src={item} />
                  </div>
                )
              })
            }
          </section>
          <section className="part-2">
            <p className="text-center p-1">{part3[1].content[3].data2.title.p1}</p>
            <p className="text-center p-2">{part3[1].content[3].data2.title.p2}</p>
            <div className="user col-xs-9">
              <div className="col-xs-2">
                <p>用户</p>
                <p>展现层</p>
              </div>
              {this.renderUser()}
            </div>
            <div className="business col-xs-9">
              <div className="col-xs-2">
                <p>业务</p>
                <p>中台</p>
              </div>
              {this.renderBusiness()}
            </div>
            <div className="data col-xs-12">
              <div className="col-xs-2">
                <p>数据</p>
                <p>中台</p>
              </div>
              <div className="col-xs-10">
                {this.renderData1()}
                {this.renderData2()}
                {this.renderData3()}
                {this.renderData4()}
              </div>
            </div>
            <div className="user col-xs-9">
              <div className="col-xs-3">
                <p>后台</p>
              </div>
              {this.renderBackStage()}
            </div>
          </section>
          <section className="part-3">
            <p className="text-center p-1">{part3[1].content[6].data5.title.p1}</p>
            <p className="text-center p-2">{part3[1].content[6].data5.title.p2}</p>
            {this.renderData5()}
          </section>
        </section>
      </div>
    )
  }
}

export default Middle;
