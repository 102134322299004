import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavigateDetailContent from '../../../components/navigate/content';
import NavigateDetailItem from '../../../components/navigate/item';
import detailJson from '../../../config/navigate/detail.json';
import RightList from '../../../components/boutique/rightList';
import information from '../../../config/boutique/information.json';
import hotBusiness from '../../../config/boutique/hotBusiness.json';
import hotDetail from '../../../config/navigate/hotDetail.json';
import newDetail from '../../../config/navigate/newDetail.json';
import NavigateList from '../../../components/navigate/list';
import './index.scss';
import { Tabs } from 'antd';
import ArticlesList from '../../../components/common/articlesList';
import HotBusiness from '../../../components/common/hotBusiness';
const { TabPane } = Tabs;

class NavigateDetail extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const result = new URLSearchParams(this.props.location.search);
    const id = result.get('id');
    const detail = detailJson.filter(item => {
      return item.id === id;
    })
    return (
      <div className="navigate-detail">
        <div className="navigate-detail-left left">
          <NavigateDetailContent data={detail[0]} id={id}/>
          <div className="merchant-offers">
            <div className="name">
              商家优享信息
            </div>
            <div className="introduction">
              <p>
                { detail[0].promotionInfo || '暂无' }
              </p>
            </div>
          </div>
          <div className="tab indexTab3" style={{ marginTop: "30px" }}>
            <div className="ivu-tabs">
              <Tabs defaultActiveKey="1" tabBarExtraContent={{ left: '精品好价'}}>
                <TabPane tab="最新" key="1">
                  {
                    (newDetail || []).map((item, i) => (
                      <NavigateDetailItem key={i} data={item}/>
                    ))
                  }
                </TabPane>
                <TabPane tab="热门" key="2">
                  {
                    (hotDetail || []).map((item, i) => (
                      <NavigateDetailItem key={i} data={item}/>
                    ))
                  }
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="navigate-detail-right right">
          <ArticlesList key={3} data={information['hot']} title={"相关文章"}/>
          <HotBusiness data={hotBusiness}/>
        </div>
      </div>
    )
  }
}

export default withRouter(NavigateDetail);
