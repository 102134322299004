import React, {Component, createContext, useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Popover} from 'antd';
import LoginModal from '../../components/login';
import PublishModal from '../../components/publish';
import './index.scss';
import store from '../../store/index';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childSolution: [
        // { solution: ['中台', '新零售', '生态营销', '金融'] }
        {solution: ['中台', '新零售', '生态营销']}
      ],
      childMore: [
        {more: ['企业介绍', '联系我们', '加入我们']}
      ],
      index: 4,
      childIndex: 0,
      route: '',
      isModalVisible: false,
      isPublishVisible: false,
      admin: {}
    };
  }
  
  renderSolution() {
    const {childSolution, index, route} = this.state;
    return childSolution.map((item, i) => (
      <ul key={i} style={{display: index === 1 ? 'block' : 'none'}} className="child-nav navbar-nav col-sm-8">
        <li className={route === 'middle' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'middle')}>{item.solution[0]}</a></li>
        <li className={route === 'minishop' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'minishop')}>{item.solution[1]}</a></li>
        <li className={route === 'ecology' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'ecology')}>{item.solution[2]}</a></li>
        <li className={route === 'finance' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'finance')}>{item.solution[3]}</a></li>
      </ul>
    ));
  }
  
  renderMore() {
    const {childMore, index, route} = this.state;
    return childMore.map((item, i) => (
      <ul key={i} style={{display: index === 3 ? 'block' : 'none'}} className="child-nav navbar-nav col-sm-8">
        <li className={route === 'introduce' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'introduce')}>{item.more[0]}</a></li>
        <li className={route === 'contact' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'contact')}>{item.more[1]}</a></li>
        <li className={route === 'join' ? "active bg col-sm-2" : "bg col-sm-2"}><a
          onClick={this.jump.bind(this, 'join')}>{item.more[2]}</a></li>
      </ul>
    ));
  }
  
  jump(type) {
    if (type === 'index') {
      this.setState({
        index: 0
      });
      this.props.history.push('/content');
    } else if (type === 'solution') {
      this.setState({
        index: 1
      });
      this.props.history.push('/middle');
    } else if (type === 'middle') {
      this.props.history.push('/middle');
    } else if (type === 'minishop') {
      this.props.history.push('/minishop');
    } else if (type === 'ecology') {
      this.props.history.push('/ecology');
    } else if (type === 'finance') {
      this.props.history.push('/finance');
    } else if (type === 'customer') {
      this.setState({
        index: 2
      });
      this.props.history.push('/customer');
    } else if (type === 'more') {
      this.setState({
        index: 3
      });
      this.props.history.push('/introduce');
    } else if (type === 'introduce') {
      this.props.history.push('/introduce');
    }
    // else if (type === 'contact') {
    //   this.props.history.push('/contact');
    // }
    // else if (type === 'join') {
    //   this.props.history.push('/join');
    // }
    else if (type === 'coupon') {
      this.setState({
        index: 4
      });
      this.props.history.push('/coupon');
    } else if (type === 'boutique') {
      this.setState({
        index: 5
      });
      this.props.history.push('/boutique');
    } else if (type === 'allCoupon') {
      this.setState({
        index: 6
      });
      this.props.history.push('/allCoupon');
    } else if (type === 'couponCommodity') {
      this.setState({
        index: 7
      })
      this.props.history.push('/couponCommodity');
    } else if (type === 'contact') {
      this.setState({
        index: 8
      })
      this.props.history.push('/contact');
    } else if (type === 'join') {
      this.setState({
        index: 9
      })
      this.props.history.push('/join');
    }
    setTimeout(() => {
      localStorage.setItem('HISTORY_INDEX', this.state.index);
    }, 50)
  }
  
  componentDidMount() {
    const num = Number(localStorage.getItem("HISTORY_INDEX"));
    const { pathname } = this.props.location;
    if (pathname === '/') {
      this.setState({
        index: 4
      });
    } else if (pathname === '/middle') {
      this.setState({
        index: 1,
        route: 'middle'
      });
    } else if (pathname === '/minishop') {
      this.setState({
        index: 1,
        route: 'minishop'
      });
    } else if (pathname === '/ecology') {
      this.setState({
        index: 1,
        route: 'ecology'
      });
    } else if (pathname === '/finance') {
      this.setState({
        index: 1,
        route: 'finance'
      });
    } else if (pathname === '/customer') {
      this.setState({
        index: 2,
        route: 'customer'
      });
    } else if (pathname === '/introduce') {
      this.setState({
        index: 3,
        route: 'introduce'
      });
    }
    // else if (pathname === '/contact') {
    //   this.setState({
    //     index: 3,
    //     route: 'contact'
    //   });
    // }
    // else if (pathname === '/join') {
    //   this.setState({
    //     index: 3,
    //     route: 'join'
    //   });
    // }
    else if (pathname === '/coupon') {
      this.setState({
        index: 4,
        route: 'coupon'
      });
    } else if (pathname === '/navigateDetail') {
      this.setState({
        index: 4,
        route: 'navigateDetail'
      });
    } else if (pathname === '/boutique') {
      this.setState({
        index: 5,
        route: 'boutique'
      });
    } else if (pathname === '/boutiqueDetail') {
      this.setState({
        index: 5,
        route: 'boutiqueDetail'
      });
    } else if (pathname === '/content') {
      this.setState({
        index: 0,
        route: 'content'
      });
    } else if (pathname === '/allCoupon') {
      this.setState({
        index: 6,
        route: 'allCoupon'
      });
    } else if (pathname === '/couponCommodity') {
      this.setState({
        index: 7,
        route: 'allCoupon'
      });
    } else if (pathname === '/couponDetail' && num === 6) {
      this.setState({
        index: 6,
        route: 'allCoupon'
      });
    } else if (pathname === '/couponDetail' && num === 7) {
      this.setState({
        index: 7,
        route: 'couponCommodity'
      });
    } else if (pathname === '/contact') {
      this.setState({
        index: 8,
        route: 'contact'
      })
    } else if (pathname === '/join') {
      this.setState({
        index: 9,
        route: 'join'
      })
    }
    this.props.history.listen(route => {
      this.state.route = route.pathname.substr(1, route.pathname.length - 1);
      window.scrollTo(0, 0);
      if (route.pathname === '/middle') {
        this.setState({
          index: 1
        });
      } else if (route.pathname === '/minishop') {
        this.setState({
          index: 1
        });
      } else if (route.pathname === '/ecology') {
        this.setState({
          index: 1
        });
      } else if (route.pathname === '/finance') {
        this.setState({
          index: 1
        });
      }
    });
    this.setState({
      admin: JSON.parse(sessionStorage.getItem(("ADMIN") || {}))
    })
    window.addEventListener("setItemEvent", (e) => {
      this.setState({
        admin: JSON.parse(e.newValue)
      })
    });
    store.subscribe(() => {
      if (store.getState()) {
        const {isModalVisible} = this.state;
        this.setState(({
          isModalVisible: !isModalVisible
        }));
      }
    })
  }
  
  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      if (window.navigator.userAgent.indexOf("MSIE") < 1) { // 判断IE浏览器
        document.body.scrollTo(0, 0);
      }
    }
  }
  
  onShowLogin() {
    const {isModalVisible} = this.state;
    this.setState(({
      isModalVisible: !isModalVisible
    }));
  }
  
  onLoginOut() {
    sessionStorage.setItem("ADMIN", JSON.stringify({
      userid: "",
      username: ""
    }))
  }
  
  onPublish() { // 发布优惠券
    const {isPublishVisible} = this.state;
    this.setState({
      isPublishVisible: !isPublishVisible
    })
  }
  render() {
    const { index, isModalVisible, admin, isPublishVisible } = this.state;
    return (
      <div id="top" className="nav">
        <div className="box">
          <nav className="navbar navbar-default col-sm-12" role="navigation">
            <div className="col-sm-2"></div>
            <div className="navbar-header col-sm-2">
              <a className="navbar-brand"><img className="logoImg" src={"img/index/logo@2x.png"}/></a>
            </div>
            <div className="navbar-nav col-sm-4">
              <ul className="nav navbar-nav">
                <li className={index === 4 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a
                onClick={this.jump.bind(this, 'coupon')}>首页</a></li>
                <li className={index === 6 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a
                  onClick={this.jump.bind(this, 'allCoupon')}>优惠券</a></li>
                <li className={index === 7 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a
                  onClick={this.jump.bind(this, 'couponCommodity')}>超值分享</a></li>
                <li className={index === 8 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a
                  onClick={this.jump.bind(this, 'contact')}>联系我们</a></li>
                <li className={index === 9 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a
                  onClick={this.jump.bind(this, 'join')}>加入我们</a></li>
                {/*<li className={index === 0 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a*/}
                {/*  onClick={this.jump.bind(this, 'index')}>企业内容</a></li>*/}
                {/*<li className={index === 1 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a*/}
                {/*  onClick={this.jump.bind(this, 'solution')}>解决方案</a></li>*/}
                {/*<li className={index === 2 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a*/}
                {/*  onClick={this.jump.bind(this, 'customer')}>客户案例</a></li>*/}
                {/*<li className={index === 3 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a*/}
                {/*  onClick={this.jump.bind(this, 'more')}>获取更多</a></li>*/}
                {/*<li className={index === 5 ? "active col-sm-2 bg" : "col-sm-2 bg"}><a*/}
                {/*  onClick={this.jump.bind(this, 'boutique')}>精品好价</a></li>*/}
              </ul>
            </div>
            <div className="navbar-btn col-sm-2">
              <div className="col-sm-10 publish">
                {
                  <Button type="primary" size={"default"} shape="round" danger onClick={(admin || {}).username ? this.onPublish.bind(this) : this.onShowLogin.bind(this)}>
                    发布优惠券
                  </Button>
                }
              </div>
              <div className="col-sm-4 login">
                {
                  (admin || {}).username ?
                    <Popover
                      content={
                        <Button type="link" danger onClick={this.onLoginOut.bind(this)}>
                          退出登录
                        </Button>
                      }
                      trigger="hover">
                      <Button type="link" size={"default"}>
                        { admin.username }
                      </Button>
                    </Popover>
                    :
                    <Button type="primary" size={"default"} shape="round" onClick={this.onShowLogin.bind(this)}>
                      登录
                    </Button>
                }
              </div>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-12 child-nav-box">
              {this.renderSolution()}
              {this.renderMore()}
            </div>
          </nav>
        </div>
        <PublishModal showPublish={this.onPublish.bind(this)} isPublishVisible={isPublishVisible}/>
        <LoginModal showLogin={this.onShowLogin.bind(this)} isModalVisible={isModalVisible}/>
      </div>
    );
  }
}

export default withRouter(Nav);
