import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import './index.scss';

function AllCouponRightItem(props) {
  const { data } = props;
  const toDetail = (id,type) => {
     if (type === 'couponDetail') {
      props.history.push(`/couponDetail?id=${id}`);
    } else if (type === 'commodityDetail') {
      props.history.push(`/commodityDetail?id=${id}`);
    };
  }
  
  return (
    <li className="all-coupon-right-item">
      <img src={ data.logo } />
      <span className="coupon-right">
        <Button className="button-link" type="link" onClick={() => toDetail(data.id,data.type)}>{ data.title }</Button>
        <span className="coupon-bottom">
          {
            data.desc ?
              <span className="desc">{ data.desc }</span>
            :
              <span className="desc">已领：{ data.cost }</span>
          }
          <Button type="primary" onClick={() => toDetail(data.id,data.type)}>去抢购</Button>
        </span>
      </span>
    </li>
  )
}

export default withRouter(AllCouponRightItem);
