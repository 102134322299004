import React, { Component } from 'react';
// import { getOffsetTop } from '../../utils/common-utils';
import { Link } from 'react-router-dom';
import Swiper from "swiper";
import "swiper/css/swiper.css";

import part1 from '../../config/home/part1.json';
import part2 from '../../config/home/part2.json';
import part3 from '../../config/home/part3.json';
import part4 from '../../config/home/part4.json';
import part5 from '../../config/home/part5.json';

import './index.scss';

const shape = 'img/index/shape.png';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberDataList: part2[1].content,
      businessDataList: part3[1].content
    }
  }
  renderNum() {
    const { numberDataList } = this.state;
    return numberDataList.map(item => (
      <section key={item.id} className="col-xs-3 list">
        <section className="each-list">
          <div className="top-line"></div>
          <div className="left-line"></div>
          <div className="bottom-line"></div>
          <div className="right-line"></div>
          <p className="p-1"><span className="title col-xs-7">{item.title}</span><img className="shape col-xs-4" src={shape} /></p>
          <p className="p-2"></p>
          <p className="p-3">{item.content}</p>
        </section>
      </section>
    ))
  }
  renderBusiness() {
    const { businessDataList } = this.state;
    return businessDataList.map(item => (
      <section key={item.id} className="col-xs-3 descr">
        <div className="mask"></div>
        <div className="mask-content">
          <img src={item.icon} />
          <p className="line"></p>
          <p className="name">{item.name}</p>
        </div>
        <img src={item.img} />
        <div className="descr-content">
          <div className="content">
            <p className="p-4 text-center">{item.name}</p>
            <p className="p-5">{item.content}</p>
            <p className="p-6 text-center"><Link to={item.link}>查看详情</Link></p>
          </div>
        </div>
      </section>
    ))
  }
  renderLogo() {
    return part5[1].content.map((item, i) => (
      <div key={i} className="cont-content" style={{ left: item.left, top: item.top }}>
        <div className="intr">
          <img src={item.img} />
        </div>
      </div>
    ))
  }
  // handleScroll() {
  //   var window_height = document.documentElement.clientHeight;
  //   window.onresize = function () { window_height = document.documentElement.clientHeight; };
  //   var leftEleven = document.getElementsByClassName('eleven-left')[0];
  //   var rightEleven = document.getElementsByClassName('eleven-right')[0];
  //   var _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  //   if (leftEleven.classList.contains("fade-in-left") === false && _scrollTop >= getOffsetTop(leftEleven) - window_height && _scrollTop <= getOffsetTop(leftEleven)) {
  //     leftEleven.classList.add("fade-in-left");
  //   }
  //   if (rightEleven.classList.contains("fade-in-right") === false && _scrollTop >= getOffsetTop(rightEleven) - window_height && _scrollTop <= getOffsetTop(rightEleven)) {
  //     rightEleven.classList.add("fade-in-right");
  //   }
  // }
  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll, true);
    new Swiper('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }
  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll, true);
  }
  render() {
    return (
      <div className="home">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/index/banenr@2x.png'} />
          {/* 嵌入banner图 */}
          <section className="col-xs-10 insert-banner">
            <section className="fade-in-left col-xs-4">
              <p className="p-1">{part1[0].p1}</p>
              <p className="p-2"></p>
              <p className="p-3">{part1[0].p2}</p>
              <p className="p-3">{part1[0].p3}</p>
            </section>
            <section className="col-xs-2"></section>
            <section className="col-xs-3"></section>
            <section className="fade-in-right col-xs-3">
              <p className="p-1">{part1[1].p1}</p>
              <p className="p-2"></p>
              <p className="p-3">{part1[1].p2}</p>
            </section>
          </section>
        </section>
        {/* 助力数字化产业升级，让天下商家更爽快 */}
        <section className="main-1">
          <section className="container">
            <h2 className="text-center">{part2[0].title}</h2>
            <p className="text-center p-1">{part2[0].desc}</p>
            {this.renderNum()}
          </section>
        </section>
        {/* 各行业务场景全栈解决方案 */}
        <section className="main-2">
          <section className="container">
            <section className="top">
              <p className="text-center p-1">{part3[0].title}</p>
              {this.renderBusiness()}
            </section>
          </section>
        </section>
        {/* 标杆项目案例 */}
        <section className="main-3">
          <section className="container" style={{position: 'relative'}}>
            <h2 className="text-center">{part4[0].title}</h2>
            <p className="text-center p-1">{part4[0].desc}</p>
            <section className="swiper-container">
              <section className="swiper-wrapper">
                {
                  part4[1].content.map((item, i) => (
                    <section key={i} className="eleven container col-xs-12 swiper-slide">
                      <section className="eleven-left left col-xs-6">
                        <img src={item[0].img} />
                      </section>
                      <section className="eleven-right left col-xs-6">
                        <img src={item[1].img} />
                        <h3>{item[1].h3}</h3>
                        <p className="p-1">{item[1].p1}</p>
                        <p className="p-2">{item[1].p2}</p>
                      </section>
                    </section>
                  ))
                }
              </section>
              <div className="swiper-pagination"></div>
            </section>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </section>
        </section>
        {/* 合作伙伴 */}
        <section className="main-4">
          <section className="container">
            <h2 className="text-center">{part5[0].title}</h2>
            <div className="cont">
              {this.renderLogo()}
            </div>
          </section>
        </section>
      </div>
    )
  }
}

export default Home;
