import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import {Tabs, Tag} from 'antd';
const { TabPane } = Tabs;

function tagList(props) {
  const { data, title } = props;
  const colorList = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];
  
  const toTagDetail = (id) => {
    props.history.push(`/tagDetail?id=${id}`);
  }
  
  return (
    <div className="tag-all-list">
      <div className="tab indexTab3">
        <div className="ivu-tabs">
          <Tabs defaultActiveKey="1" tabBarExtraContent={{ left: title }}>
            <TabPane>
              {
                data.map((item, i) => (
                  <Tag key={i} onClick={ () => toTagDetail(item.id) } color={colorList[Math.floor(Math.random() * 11)]}>{item.name}</Tag>
                ))
              }
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default withRouter(tagList);
