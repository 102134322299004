import React, { Component } from 'react';
import './index.scss';
import CouponNavigate from '../../../components/coupon/navigate';
import couponItem from '../../../config/coupon/couponItem.json';
import CouponItem from '../../../components/coupon/couponItem';
import AllCouponRightItem from '../../../components/coupon/allCoupon/rightBoxItem';
import allCouponRightItem from '../../../config/coupon/allCouponRightItem.json';
import newCoupon from '../../../config/coupon/newCoupon.json';

class AllCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="col-sm-12 all-container" >
        <div className="col-sm-2"></div>
        <div className="all-coupon col-sm-8">
          <div className="navigate">
            <CouponNavigate/>
          </div>
          <div className="all-coupon-box">
            <div className="all-coupon-left">
              <p className="title">券老大优惠券：免费天猫、淘宝,京东优惠券,聚美优品优惠券,唯品会优惠券,1号店优惠券,苏宁易购优惠券、代金券领取。</p>
              <div className="coupon-list-item">
                {
                  couponItem.map((item, index) => (
                    <CouponItem key={index} data={item}/>
                  ))
                }
              </div>
            </div>
            <div className="all-coupon-right">
              <div className="all-coupon-list">
                <p>最新值得买</p>
                <ul>
                  {
                    allCouponRightItem.map((item, index) => (
                      <AllCouponRightItem data={item} key={index}/>
                    ))
                  }
                </ul>
              </div>
              <div className="all-coupon-list">
                <p>最新优惠券</p>
                <ul>
                  {
                    newCoupon.map((item, index) => (
                      <AllCouponRightItem data={item} key={index}/>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2"></div>
      </div>
    )
  }
}

export default AllCoupon;
