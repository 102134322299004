import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import {Tag} from 'antd';

function ArticleLeft(props) {
  const { data } = props;
  
  const onJump = (id) => {
    props.history.push(`/navigateDetail?id=${id}`);
  }
  
  return (
    <div className="article-left">
      <div className="detail-box">
        <h1 className="title">{ data.title }</h1>
        <p className="time">{ data.createdTime }</p>
        <p className="article-img"><img src={`${data.mainPicture}`} alt=""/></p>
        <div className="detail-content" dangerouslySetInnerHTML={{__html: data.content}}/>
        <div className="tags">
          {
            (data.tagList || []).map((tag, key) => (
              <Tag key={ key } color={ "blue" }>{ tag.name }</Tag>
            ))
          }
        </div>
      </div>
      <div className="contact">
        <div className="contactContent">
          <div className="contactShop">
            <div className="shopLogo">
              <img src={`${data.logo}`} alt=""/>
            </div>
            <div className="shopInfo">
              <p className="shopName">
                { data.shopName }
              </p>
              <p className="shopDesc">
                { data.desc }
              </p>
              <p className="shopLink">
                <span onClick={() => onJump(data.shopId)}>
                  免费领淘宝优惠券>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ArticleLeft);
