import React, { Component } from 'react';

import part1 from '../../../config/solution/finance/part1.json';
import part2 from '../../../config/solution/finance/part2.json';

import './index.scss';

class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgList: part1.imgList,
      imgList1: part2.imgList
    }
  }
  renderImg() {
    const { imgList } = this.state;
    return imgList.map((item, i) => (
      <section key={i} className="col-xs-6 list">
        <section className="each-list" style={{ backgroundImage: `url(${item.img})` }}>
          <div className="mask"></div>
          <div>
            <img src={'img/solution/zhengfangti.png'} />
            <span>{item.title}</span>
          </div>
        </section>
        <p className="caption">{ item.content }</p>
      </section>
    ))
  }
  renderImg1() {
    const { imgList1 } = this.state;
    return imgList1.map((item, i) => (
      <section key={i} className="col-xs-6 module">
        <section className="each-module">
          <div>
            <p className="col-xs-6 title" style={{ backgroundImage: `url(${item.img})` }}><p className="caption">{ item.title }</p></p>
            <p className="col-xs-6 detail">{ item.content }</p>
          </div>
        </section>
      </section>
    ))
  }
  render() {
    return (
      <section className="finance">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/solution/banner4.png'} />
          {/* banner图文字 */}
          <div className="content">
            <img className="fade-in-down" src={'img/solution/jinrongzhifu.png'} />
            <p className="p-1 fade-in-up">为企业提供“支付+营销+科技”的营销综合服务体系</p>
            <p className="p-1 fade-in-up">聚合微信、支付宝等各类支付功能，提供营销推广的商户服务平台</p>
            <p className="p-1 fade-in-up">具备丰富的支付通道、高效的清结算服务和精准的营销功能</p>
          </div>
        </section>
        <section className="main container">
          <div className="part-1 container">
            <p className="p-1">{part1.title.p1}</p>
            <p className="p-2">{part1.title.p2}</p>
            <div className="col-xs-10">
              { this.renderImg() }
            </div>
          </div>
          <div className="part-2 container">
            <p className="p-1">{part2.title.p1}</p>
            <p className="p-2"></p>
            <div className="col-xs-10">
              { this.renderImg1() }
            </div>
          </div>
        </section>
      </section>
    )
  }
}

export default Finance;
