import React from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';

function rightList(props) {
  const { data, i } = props;
  
  const toArticleDetail = (id) => {
    props.history.push(`/articleDetail?id=${id}`);
  }
  
  return (
    <p onClick={ () => toArticleDetail(data.id) }>
      <span className={ `sort sort${i + 1}` }>{ i + 1 }</span>
      <span>{ data.title }</span>
    </p>
  )
}

export default withRouter(rightList);
