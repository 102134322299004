import React, {useState} from 'react';
import { Button } from 'antd';
import {withRouter} from 'react-router-dom';
import { adminInfo } from '../../../utils/admin-utils';
import './index.scss';
import store from '../../../store/index';

function CouponItem(props) {
  const { data } = props;
  const admin = adminInfo() || {};
  
  const toDetail = function (id) {
    if (!admin.username) {
      store.dispatch({ type: 'loginState', payload: true })
    } else {
      props.history.push(`/couponDetail?id=${id}`);
    }
  }
  
  return (
    <div className="coupon-wrapper">
      <span className="coupon-title">{ data.title }</span>
      <div className="scissors"></div>
      <div className="coupon">
        <div className="coupon-left coupon-common">
          <p className="title">{ data.desc }</p>
          <img src={data.logo} alt=""/>
        </div>
        <div className="coupon-right coupon-common">
          <p className="title">免费</p>
          <Button type="primary" onClick={ () => toDetail(data.id) }>立即领取</Button>
        </div>
      </div>
      <p className="bottom">还剩&nbsp;<span className="text-blue">{ data.leftDay }</span>&nbsp;天&nbsp;数量：<span className="text-blue">{ data.couponAll }</span>&nbsp;&nbsp;剩余：<span className="text-blue">{ data.couponLeft }</span></p>
    </div>
  )
}

export default withRouter(CouponItem);
