import React, { Component } from 'react';
import { getOffsetTop } from '../../../utils/common-utils';

import part1 from '../../../config/solution/ecology/part1.json';

import './index.scss';

class Ecology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgList: part1.imgList,
      data1: part1.data1,
      data2: part1.data2,
      isShow: true
    }
  }
  renderImg() {
    const { imgList } = this.state;
    return imgList.map((item, i) => (
      <section key={i} className="col-xs-3 list">
        <section className="each-list" style={{ backgroundImage: `url(${item.img})` }}>
          <div className="mask"></div>
          <div>
            <img src={'img/solution/zhengfangti.png'}/>
            <span>{item.title}</span>
          </div>
        </section>
        <p className="caption"><p>{item.title}</p><p>{item.content}</p></p>
      </section>
    ))
  }
  renderData1() {
    const { data1 } = this.state;
    return data1.map((item, i) => (
      <li key={i}>
        <img src={item.img}/>
        <p className="p-1">{item.title}</p>
        <p className="p-2"></p>
        <p className="p-3">{item.content1}</p>
        <p className="p-3">{item.content2}</p>
      </li>
    ))
  }
  renderData2() {
    const { data2 } = this.state;
    return data2.map((item, i) => (
      <li key={i}>
        <p className="p-1">{item.title}</p>
        <p className="p-2"></p>
        <p className="p-3"><span>{item.content1}</span><span>{item.content2}</span></p>
        <img src={item.img}/>
      </li>
    ))
  }
  toggleShow() {
    this.setState({
      isShow: false
    })
  }
  handleScroll() {
    var window_height = document.documentElement.clientHeight;
    window.onresize = function () { window_height = document.documentElement.clientHeight; };
    var leftEleven = document.getElementsByClassName('left-content')[0];
    var rightEleven = document.getElementsByClassName('right-content')[0];
    var _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    if (leftEleven.classList.contains("fade-in-left") === false && _scrollTop >= getOffsetTop(leftEleven) - window_height && _scrollTop <= getOffsetTop(leftEleven)) {
      leftEleven.classList.add("fade-in-left");
    }
    if (rightEleven.classList.contains("fade-in-right") === false && _scrollTop >= getOffsetTop(rightEleven) - window_height && _scrollTop <= getOffsetTop(rightEleven)) {
      rightEleven.classList.add("fade-in-right");
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }
  render() {
    const { isShow } = this.state;
    return (
      <section className="ecology">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/solution/banner3.png'} />
        </section>
        <section className="main container">
          <div className="part-1 container">
            <p className="text-center p-1">{part1.title.p1}</p>
            <p className="text-center p-2">{part1.title.p2}</p>
            <div className="col-xs-12">
              { this.renderImg() }
            </div>
          </div>
          <div className="part-2 container">
            <ul className="left-content col-xs-6">
              { this.renderData1() }
            </ul>
            <ul className="right-content col-xs-6">
              { this.renderData2() }
            </ul>
          </div>
        </section>
        {/* 客服热线 */}
        <section className={isShow ? "customer-service" : "fade-out-dialog"} style={{display: isShow ? 'block' : 'none'}}>
          <div className="mask"></div>
          <div className={isShow ? "content fade-in-dialog" : "content"}>
            <p className="p-1">客服热线</p>
            <p className="p-2">020-38915126</p>
            <p className="p-3"><span onClick={this.toggleShow.bind(this)}>继续浏览</span></p>
          </div>
          {/* <img onClick={this.toggleShow.bind(this)} src={'img/solution/guanbi.png'}/> */}
        </section>
      </section>
    )
  }
}

export default Ecology;
