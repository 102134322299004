import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';

function CouponBoxItem(props) {
  const { data } = props;
  
  const toCouponDetail = (id, key) => {
    if (key === 1) {
      props.history.push(`/couponDetail?id=${id}`);
    } else if (key === 2) {
      props.history.push(`/commodityDetail?id=${id}`);
    }
  }
  
  return (
    <div className="box-item">
      <div className="boutique">
        <div className="externalCoupons">
          <div className="pic">
            <img src={`${data.logo}`} alt=""/>
          </div>
          <div className="info">
            <p className="box-title">
              <span onClick={ () => toCouponDetail(data.id, data.key) }>
                <img src="/img/coupon/ding.png" alt=""/>
                { data.title1 }
              </span>
            </p>
            <div className="discount">
              <p>
                <span className="couponPrice">
                  <span>{ data.title2 }</span>
                </span>
                <span className="desc">
                  {
                    data.content.map((item, index) => (
                      <span key={index}>
                        { item }
                      </span>
                    ))
                  }
                </span>
              </p>
            </div>
            <p className="box-bottom">
              {
                data.tags.map((item, index) => (
                  <span key={index} className="coupon-tag">
                    { item }
                  </span>
                ))
              }
              <span className="receiveButton" onClick={ () => toCouponDetail(data.id, data.key) }>
                直达链接
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(CouponBoxItem);
