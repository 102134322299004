import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import { adminInfo } from '../../../utils/admin-utils';
import {Tag} from 'antd';

function ListItem(props) {
  const { data, i, type } = props;
  const admin = adminInfo() || {};
  const onJump = (link) => {
    window.open(link);
  };
  
  const toDetail = (i) => {
    props.history.push(`/boutiqueDetail?index=${i}`);
  };
  
  return (
    <div key={i} className="discount-boutique">
      <div className="externalCoupons">
        <div className="pic">
          <img src={data.pictUrl} alt=""/>
        </div>
        <div className="info">
          <p className="title" onClick={ () => toDetail(i) }>
            { data.title }
          </p>
          <div className="couponQuota">
            <p className="coupon">￥{ data.couponAmount }</p>
            <p className="rebateQuota">返{ data.backPrice }</p>
          </div>
          <div className="discount">
            <p>
              <span className="couponPrice">
                <span>¥{ data.finalPrice }</span>
                券后价
              </span>
              <span className="realPrice">
                ¥{ data.zkFinalPrice }
              </span>
            </p>
            <p>
              <span className="realPrice">
                月销 { data.volume }
              </span>
              {
                (admin || {}).username ?
                  <span className="receiveButton" onClick={() => onJump(data.link)}>
                    领取优惠码
                  </span>
                  :
                  null
              }
            </p>
          </div>
          <div className="tags">
            {
              type === 'detail' ?
              (data.tags || []).map((tag, key) => (
                <Tag key={ key } color={ "blue" }>{ tag }</Tag>
              ))
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ListItem);
