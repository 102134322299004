import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import part1 from '../../config/footer/part1.json';

import './index.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: part1.dataList
    }
  }
  renderDetail() {
    const { dataList } = this.state;
    return dataList.map((item, i) => (
      <ul className="col-sm-10" key={i}>
        <li className="col-sm-2">{item.first.name}</li>
        <li className="col-sm-2">{item.second.path ? <a onClick={this.jump.bind(this, item.second.path)}>{item.second.name}</a> : item.second.name}</li>
        <li className="col-sm-2">{item.third.path ? <a onClick={this.jump.bind(this, item.third.path)}>{item.third.name}</a> : item.third.name}</li>
        <li className="col-sm-2"><a onClick={this.jump.bind(this, item.fourth.path)}>{item.fourth.name}</a></li>
        <li className="col-sm-2"><a>{item.fifth.name}</a></li>
      </ul>
    ))
  }
  jump(path) {
    if (path) {
      this.props.history.push(path);
    }
  }
  jumpGov() {
    window.open("https://beian.miit.gov.cn/")
  }
  render() {
    return (
      <section id="footer" className="footer container">
        {/*<section className="col-sm-12 detail">*/}
        {/*  { this.renderDetail() }*/}
        {/*  <div>*/}
        {/*    <img src={'img/index/gongzhonghao.png'} />*/}
        {/*    <span>扫码关注公众号</span>*/}
        {/*  </div>*/}
        {/*</section>*/}
        <section className="col-sm-10 address">
          <div>
            <p>广州宇中网络科技有限公司 /公司邮箱:info@unizone.tech</p>
            {/* <p>©2015-2020 广州宇中网络科技有限公司 <a style={{ color: '#fff' }} target="_blank" href="https://beian.miit.gov.cn/">粤ICP备16009960号-2</a></p> */}
          </div>
          <div>
            <p>联系电话：020-38915126</p>
          </div>
          <div style={{ textAlign: 'right' }}>
            <p>地址：广州市天河区林和西路157号保利中汇广场A座505</p>
          </div>
        </section>
        <section className="col-sm-10 foot">
          <p>©2015-2022 广州宇中网络科技有限公司 <span className="link" style={{ color: '#fff', cursor: 'pointer' }} onClick={this.jumpGov.bind(this)}>粤ICP备16009960号</span></p>
          <p>ICP许可证编号：粤B2-20180034</p>
        </section>
      </section>
    )
  }
}

export default withRouter(Footer);
