import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';

function NavigateDetailContent(props) {
  const { data } = props;
  
  const onJump = (link) => {
    window.open(link)
  }
  
  return (
    <div className="navigate-detail-content">
      <div className="boutique">
        <div className="externalCoupons">
          <div className="pic">
            <img src={`${data.logo}`} alt=""/>
          </div>
          <div className="info">
            <p className="title">
              { data.name }
            </p>
            <div className="discount">
              <p>
                <span className="couponPrice">
                  <span>{ data.subTitle }</span>
                </span>
                <span className="desc">
                  <span>{ data.desc }</span>
                </span>
              </p>
            </div>
            <p>
              <span className="receiveButton" onClick={() => onJump(data.link)}>
                直达链接
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(NavigateDetailContent);
