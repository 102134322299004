import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';
import {Tabs} from 'antd';
import Navigate from '../../../config/coupon/navigate.json';
import CouponNavigateItem from '../navigateItem';

const { TabPane } = Tabs;

const onChange = (key) => {
};

function CouponNavigate(props) {
  return (
    <div className="coupon-navigate">
      <Tabs onChange={onChange} type="card">
        {
          Navigate.map((item, index) => (
            <TabPane tab={item.name} key={index}>
              <CouponNavigateItem type={item.type}/>
            </TabPane>
          ))
        }
      </Tabs>
    </div>
  )
}

export default withRouter(CouponNavigate);
