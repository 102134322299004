import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';

function NavigateList(props) {
  const { data } = props;
  
  const toNavigateDetail = () => {
    props.history.push(`/navigateDetail?id=${data.id}`);
  }
  
  return (
    <li onClick={ () => toNavigateDetail() }>
      <p className="top">
        <img src={`${data.logo}`}/>
      </p>
      <p className="name">
        { data.name }
      </p>
    </li>
  )
}

export default withRouter(NavigateList);
