import React, {useState} from 'react';
import './index.scss';
import couponDetail from '../../../config/coupon/couponDetail.json';
import couponItem from '../../../config/coupon/couponItem.json';
import hotBusiness from '../../../config/coupon/hotBusiness.json';
import MoreCoupon from '../../../components/coupon/moreCoupon';
import CouponItem from '../../../components/coupon/couponItem';
import BusinessItem from '../../../components/coupon/business';
import CouponHotBusiness from '../../../components/coupon/hotBusiness';
import {Button, message} from 'antd';
import item from '../../../components/navigate/item';
import {adminInfo} from '../../../utils/admin-utils';
import store from '../../../store';

function DetailInner(props) {
  const result = new URLSearchParams(props.location.search);
  const id = result.get('id');
  const data = couponDetail.filter(item => {
    return item.id === id;
  })[0];
  const admin = adminInfo() || {};
  
  const onReceive = function() {
    if (!admin.username) {
      store.dispatch({ type: 'loginState', payload: true })
    } else {
      message.success('领取成功');
    }
  }
  
  return (
    <div className="all-container col-sm-12">
      <div className="col-sm-2"></div>
      <div className="detail-inner col-sm-8">
        <div className="new-box">
          <div className="clear">
            <div className="box-left">
              <div className="main-l-l-d">
                <div className="top-main">
                  <div className="box-detail-img">
                    <img src={data.pic} alt=""/>
                  </div>
                  <div className="normal-m">
                    {/*<p><span>{data.typeName}网址：</span><a className="link" href={data.link} target="_blank">{ data.url }</a></p>*/}
                    <p><span>{data.typeName}电话：</span><span>{data.phone}</span></p>
                    <p><span>{data.typeName}介绍：</span>
                      {
                        data.introduction.map((ele, i) => (<span key={i}>{ele}<br/></span>))
                      }
                    </p>
                    <p><Button className="receive-coupon" type="primary" onClick={ () => onReceive() }>领取优惠券</Button></p>
                  </div>
                </div>
                <div className="coupon-list">
                  <h4>唯品会相关优惠券</h4>
                  <div className="coupon-list-item">
                    {
                      couponItem.map((item, index) => (
                        <CouponItem key={index} data={item}/>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="box-right">
              <BusinessItem data={data.businessInfo}/>
              <MoreCoupon/>
              <CouponHotBusiness data={hotBusiness}/>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-2"></div>
    </div>
  );
}

export default DetailInner;
