import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { adminInfo } from '../../../utils/admin-utils';
import './index.scss';

function tagDetailContent(props) {
  const { data } = props;
  const admin = adminInfo() || {};
  
  const onJump = (link) => {
    window.open(link)
  }
  
  return (
    <div className="tag-detail-content">
      <div className="boutique">
        <p className="content-title">
          <span>标签相关</span>
          <span>
            {
              (() => {
                switch (data.type) {
                  case 'shop':
                    return '商品'
                  case 'coupon':
                    return '优惠'
                  case 'article':
                    return '文章'
                }
              })()
            }
          </span>
        </p>
        <div className="externalCoupons">
          <div className={ data.type === 'shop' ? "pic" : (data.type === 'article' ? 'pic-ar' : 'pic') }>
            {
              (() => {
                switch (data.type) {
                  case 'shop':
                    return <img src={`${data.logo}`} alt=""/>
                  case 'coupon':
                    return <img src={`${data.logo.indexOf("https") > -1 ? data.logo : `${data.logo}`}`} alt=""/>
                  case 'article':
                    return <img src={`${data.mainPicture}`} alt=""/>
                }
              })()
            }
          </div>
          <div className="info">
            <p className="title">
              {
                (() => {
                  switch (data.type) {
                    case 'shop':
                      return data.name
                    case 'coupon':
                      return data.title
                    case 'article':
                      return data.title
                  }
                })()
              }
            </p>
            <div className="time">
              {
                (() => {
                  switch (data.type) {
                    case 'article':
                      return data.createdTime
                  }
                })()
              }
            </div>
            <div className="discount">
              <p>
                <span className="couponPrice">
                  <span>{ data.subTitle }</span>
                </span>
                <span className="desc">
                  <span>{ data.desc }</span>
                  {
                    data.content ?
                      <span dangerouslySetInnerHTML={{__html: data.content}}/>
                      : null
                  }
                </span>
              </p>
            </div>
            <p>
              {
                (() => {
                  switch (data.type) {
                    case 'shop':
                      return <span className="receiveButton" onClick={() => onJump(data.link)}>直达链接</span>
                    case 'coupon':
                      return (admin || {}).username ? <span className="receiveButton" onClick={() => onJump(data.link)}>领取优惠码</span> : null
                  }
                })()
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(tagDetailContent);
