import React, {useState} from 'react';
import { Button, message } from 'antd';
import CouponItem from '../../../components/coupon/couponItem';
import BusinessItem from '../../../components/coupon/business';
import CouponHotBusiness from '../../../components/coupon/hotBusiness';
import './index.scss';
import couponDetail from '../../../config/coupon/couponDetail.json';
import couponItem from '../../../config/coupon/couponItem.json';
import hotBusiness from '../../../config/coupon/hotBusiness.json';
import MoreCoupon from '../../../components/coupon/moreCoupon';
import store from '../../../store/index';
import { withRouter } from 'react-router-dom';
import {adminInfo} from '../../../utils/admin-utils';

function CouponDetail(props) {
  const result = new URLSearchParams(props.location.search);
  const id = result.get('id');
  const data = couponDetail.filter(item => {
    return item.id === id;
  })[0];
  const admin = adminInfo() || {};
  
  const onJump = (link) => {
    window.open(link);
  }

  const onJumpDetail = (id) => {
    props.history.push(`/detailInner?id=${id}`);
  }
  
  const onReceive = () => {
    if (!admin.username) {
      store.dispatch({ type: 'loginState', payload: true })
    } else {
      message.success('领取成功');
    }
  }
  
  return (
    <div className="all-container col-sm-12">
      <div className="col-sm-2"></div>
      <div className="coupon-detail col-sm-8">
        <div className="new-box">
          <div className="clear">
            <div className="box-left">
              <div className="main-l-l-d">
                <div className="top-title">
                  <h3>{ data.title }</h3>
                  <Button type="link" onClick={()=>onJumpDetail(data.id)}>{ data.typeName }优惠券免费领取</Button>
                </div>
                <div className="top-main">
                  <div className="box-detail-img">
                    <p className="title">{ data.desc }</p>
                    <img src={ data.pic } alt="" onClick={()=>onJumpDetail(data.id)}/>
                  </div>
                  <div className="normal-m">
                    <p><span>有效期：</span><span>{ data.validityDate }</span></p>
                    <p><span>领取限制：</span><span>每月最多领{ data.limitMonth }张优惠券，每天限领{ data.limitDay }张优惠券</span></p>
                    <p><span>优惠券数：</span><span>总 <span className="text-red">{ data.couponAll }</span> 张 已领 <span className="text-red">{ data.couponCost }</span> 张 剩余 <span className="text-red">{ data.couponLeft }</span> 张</span></p>
                    <p><span>更多券：</span><span>更多<Button type="link">{ data.typeName }优惠券</Button></span></p>
                    <p>
                      <Button onClick={ () => onReceive() }>立即领取</Button>
                      {/*<Button onClick={ () => onJump(data.link) }>去商家用券</Button>*/}
                    </p>
                  </div>
                </div>
                <div className="middle-main">
                  <h4>使用条件</h4>
                  <div className="content">
                    {
                      data.useCondition.map((item, index) => (
                        <p key={index}>
                          { item }
                        </p>
                      ))
                    }
                  </div>
                  <h4>使用说明</h4>
                  <div className="rich-text">
                    {
                      data.useExplain.map((item, index) => (
                        <p key={index}>
                          { item }
                        </p>
                      ))
                    }
                    {
                      data.richImg.map((item, index) => (
                        <p key={index}>
                          <img src={ item } alt=""/>
                        </p>
                      ))
                    }
                  </div>
                  <h4>商家介绍</h4>
                  <div className="introduction">
                    {
                      data.introduction.map((item, index) => (
                        <p key={index}>
                          { item }
                        </p>
                      ))
                    }
                  </div>
                  <div className="tips">
                    <p>温馨提示：</p>
                    {
                      data.tips.map((item, index) => (
                        <p key={index}>
                          { item }
                        </p>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="coupon-list">
                <h4>唯品会相关优惠券</h4>
                <div className="coupon-list-item">
                  {
                    couponItem.map((item, index) => (
                      <CouponItem key={index} data={item}/>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="box-right">
              <BusinessItem data={data.businessInfo}/>
              <MoreCoupon/>
              <CouponHotBusiness data={hotBusiness}/>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-2"></div>
    </div>
  )
}

export default withRouter(CouponDetail);
