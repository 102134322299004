import React from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';

function CommodityLike(props) {
  const { changeDetail } = props;
  const toDetail = (id) => {
    changeDetail(id);
  };
  return (
    <ul className="commodity-like">
      {
        props.list.map((item, index) => (
          <li key={index} className="like-item">
            <a className="img" onClick={() => toDetail(item.id)}>
              <img src={item.image}></img>
            </a>
            <div>
              <a onClick={() => toDetail(item.id)} className='text'>
                {item.blackTitle}
                <span className="error-color mg-l5">{item.redTitle}</span>
              </a>
            </div>
          </li>
        ))
      }
    </ul>
  );
}


// export default CommodityLike;
export default withRouter(CommodityLike);
