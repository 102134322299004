import React from 'react';
import {withRouter} from 'react-router-dom';
import { Button } from 'antd';
import './index.scss';

function HotBusiness(props) {
  const { data } = props;
  
  const toDetail = (id) => {
    props.history.push(`couponDetail?id=${id}`);
  }
  
  return (
    <div className="coupon-hot-business">
      <p>综合商城最新优惠券</p>
      <ul className="list">
        {
          data.map((item, i) => (
            <li key={i} onClick={ () => toDetail(item.id) }>
              <img src={item.logo} alt=""/>
              <Button type="link">{item.title}</Button>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default withRouter(HotBusiness);
