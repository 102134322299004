import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import { adminInfo } from '../../../utils/admin-utils';
import './index.scss';

function DiscountItem(props) {
  const { data, i } = props;
  const admin = adminInfo() || {};
  
  const onJump = (link) => {
    window.open(link);
  };
  
  return (
    <div className="boutique-list">
      {
        1 ?
          (
            <div className="externalCoupons">
              <span className={`sort sort${i + 1}`}>{i + 1}</span>
              <div className="pic">
                <img src={data.pictUrl} alt=""/>
              </div>
              <div className="info">
                <p className="title">
                  {data.title}
                </p>
                <div className="discount">
                  <p>
                    <span className="couponPrice">
                      <span>¥{data.finalPrice}</span>
                      券后价
                    </span>
                    <span className="realPrice">
                      ¥{data.zkFinalPrice}
                    </span>
                  </p>
                  <p>
                    {
                      (admin || {}).username ?
                        <span className="receiveButton" onClick={() => onJump(data.link)}>
                          领取优惠码
                        </span>
                        :
                        null
                    }
                  </p>
                </div>
              </div>
            </div>
          )
          :
          <ul>
            <li>
              <span className={`sort sort${i + 1}`}>{i + 1}</span>
              <span className="sub-title">{data.subTitle}</span>
              <span className="title">{data.title}</span>
            </li>
          </ul>
      }
    </div>
  );
}

export default withRouter(DiscountItem);
