import React, { Component } from 'react';
import ListItem from '../../../components/boutique/list';
import shopList from '../../../config/boutique/shopList.json';
import discountList from '../../../config/boutique/discountList.json';
import DiscountItem from '../../../components/boutique/discount';
import hotBusiness from '../../../config/boutique/hotBusiness.json';
import NavigateList from '../../../components/navigate/list';
import RightList from '../../../components/boutique/rightList';
import information from '../../../config/boutique/information.json';
import './index.scss';
import {Tabs} from 'antd';
import ArticlesList from '../../../components/common/articlesList';
import HotBusiness from '../../../components/common/hotBusiness';
const { TabPane } = Tabs;

class BoutiqueDetail extends Component {
  constructor(props) {
    super(props);
    const result = new URLSearchParams(this.props.location.search);
    const i = result.get('index');
    this.state = {
      detailIndex: i
    }
  }
  renderShopDetail() {
    const { detailIndex } = this.state;
    return (
      <div>
        <ListItem className="list-item" data={shopList[detailIndex]} type={"detail"} />
      </div>
    )
  }
  render() {
    const { detailIndex } = this.state;
    return (
      <div className="boutique-detail">
        <div className="left">
          { this.renderShopDetail() }
          <div className="reason" style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
            <h4>推荐理由：</h4>
            <span className="reason-content">
              { (shopList[detailIndex] || {}).reason }
            </span>
          </div>
          <div className="reason" style={{ borderRadius: "10px" }}>
            <h4>领取返利：</h4>
            {
              ((shopList[detailIndex] || {}).rebate || []).map((item, index) => (
                <p key={index} className="rebate">
                  { item }
                </p>
              ))
            }
          </div>
          <div className="discount-code">
            <p className="discount-title">相关优惠码</p>
            {
              discountList.map((item, index) => (
                <DiscountItem key={index} data={item} i={index}/>
              ))
            }
          </div>
          <div className="discount-code">
            <ArticlesList key={4} data={information['introduction']} title={"相关文章"}/>
          </div>
        </div>
        <div className="right">
          <div className="tab indexTab3">
            <div className="ivu-tabs">
              <HotBusiness data={hotBusiness}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BoutiqueDetail;
