import React, {useState} from 'react';
import './index.scss';

function CommodityTop(props) {
    return(
        props.top.map((item,index) => (
            <div key={index} className="commodity-top">
                <div className="title">
                    <img src="/img/coupon/zhiding.gif" />
                    <a className='title-a' href={'#/commodityDetail?id='+item.id}>
                        <span className='title-red'>{item.blackTitle}</span>
                        <span className="error-color">{item.redTitle}</span>
                    </a>
                </div>
                <img src="/img/coupon/commodity/link.jpg" width="100%" height="1"/>
            </div>
        ))
    )
}


export default CommodityTop;
