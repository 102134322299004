import React, {Component} from 'react';
import './index.scss';
import CommodityDetailCom from '../../../components/coupon/commodityDetailCom';
import list from '../../../config/coupon/commodityList.json';
import likeList from '../../../config/coupon/commodityLike.json';
import CommodityLike from '../../../components/coupon/commodityLike';
import BoxSwiper from '../../../components/coupon/boxSwiper';
import hot from '../../../config/coupon/hot.json';
import RightBoxItem from '../../../components/coupon/rightBoxItem';

class CommodityDetail extends Component {
  constructor(props) {
    super(props);
    const result = new URLSearchParams(this.props.location.search);
    this.state = {
      id: result.get('id') || undefined,
      detail: list.filter(ele => ele.id === result.get('id'))[0] || {}
    };
  }
  
  changeDetail(id) {
    this.setState({
      detail: list.filter(ele => ele.id === id)[0] || {}
    })
  }
  
  render() {
    return (
      <div className="all-container col-sm-12">
        <div className="col-sm-2"></div>
        <div className="commodity-detail col-sm-7">
          <div className="commodity-detail-left">
            <CommodityDetailCom detail={this.state.detail}/>
            <div className="like-title">您可能还喜欢</div>
            <img className="img" src="/img/coupon/commodity/link-sm.jpg"/>
            <CommodityLike list={likeList} changeDetail={this.changeDetail.bind(this)}/>
          </div>
          <div className="commodity-detail-right">
            <BoxSwiper/>
            <div className="box-list">
              <div className="right-title">
                <i/>
                一周最热网购优惠券
              </div>
              {
                hot.coupon.map((item, index) => (
                  <RightBoxItem key={index} data={item}/>
                ))
              }
            </div>
            <div className="box-list">
              <div className="right-title">
                <i/>
                一周最热值得买
              </div>
              {
                hot.buy.map((item, index) => (
                  <RightBoxItem key={index} data={item}/>
                ))
              }
            </div>
            <div className="box-list">
              <div className="right-title">
                <i/>
                一周最热出行外卖
              </div>
              {
                hot.travel.map((item, index) => (
                  <RightBoxItem key={index} data={item}/>
                ))
              }
            </div>
          </div>
        </div>
        <div className="col-sm-2"></div>
      </div>
    );
  }
  
}

export default CommodityDetail;
