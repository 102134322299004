import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import filter from '../../../config/boutique/filter.json';
import shopList from '../../../config/boutique/shopList.json';
import information from '../../../config/boutique/information.json';
import ListItem from '../../../components/boutique/list';
import RightList from '../../../components/boutique/rightList';
import TagList from '../../../components/common/tagList';
import ArticlesList from '../../../components/common/articlesList';

class Boutique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeIndex: -1,
      shopIndex: -1,
      shopItem: shopList
    }
  }
  renderFilter() {
    return (
      <div className="tabBar">
        <div className="sort">
          <p>分类：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderType()
              }
            </ul>
          </div>
        </div>
        <div className="sort type">
          <p>商城：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderShop()
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
  renderType() { // 分类
    const { typeIndex } = this.state;
    const { type } = filter;
    let html = '';
    html = type.map((item, i) => (
      <li className={ typeIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'type')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderShop() { // 商城
    const { shopIndex } = this.state;
    const { shop } = filter;
    let html = '';
    html = shop.map((item, i) => (
      <li className={ shopIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'shop')}>
        { item.name }
      </li>
    ))
    return html;
  }
  filter(condition, data) { // 条件过滤
    return data.filter(item => {
      return Object.keys(condition).every(key => {
        if (key) {
          return String(item[key]).toLowerCase().includes(
            String(condition[key]).trim().toLowerCase()
          )
        }
      })
    })
  }
  renderActive(index, type) {
    this.setState({
      [`${type}Index`]: index
    }, () => {
      const { typeIndex, shopIndex } = this.state;
      const { shop } = filter;
      const type1 = filter.type;
      let temp;
      temp =  {
        type: ((type1[typeIndex] || {}).type === 'all' ? '' : (type1[typeIndex] || {}).type) || '',
        shop:  ((shop[shopIndex] || {}).type === 'all' ? '' : (shop[shopIndex] || {}).type) || ''
      };
      this.setState({
        shopItem: this.filter(temp, shopList)
      })
    })
  }
  renderShopList() {
    const { shopItem } = this.state;
    return shopItem.map((item, i) => (
      <ListItem key={i} data={item} i={i}/>
    ))
  }
  renderInformation(type) { // 时尚资讯
    return (
      <div className="article-list">
        <ul>
          { this.renderInformationItem(information[type]) }
        </ul>
      </div>
    );
  }
  renderInformationItem(list) {
    return list.map((item, i) => {
      return (
        <RightList data={item} key={i} i={i}/>
      )
    })
  }
  renderTag() {
    const { tag } = information;
    return (
      <TagList data={tag} title={"时尚标签"}/>
    )
  }
  render() {
    const { shopItem } = this.state;
    const tabs = [
      { name: '最新', list: information['new'] },
      { name: '热门', list: information['hot'] }
    ]
    return (
     <div className="tab-content">
       { this.renderFilter() }
       <div className="boutique-box">
         <div className="left">
           {
             shopItem.length ?
               this.renderShopList()
               :
               <div className="none">
                 <div className="none-item"/>
                 <p>暂无数据</p>
               </div>
           }
         </div>
         <div className="right">
           <ArticlesList key={1} tabs={tabs} title={"时尚资讯"}/>
           <ArticlesList key={2} data={information['hot']} title={"最新攻略"}/>
           { this.renderTag() }
         </div>
       </div>
     </div>
   )
  }
}

export default withRouter(Boutique);
