import React, {Component} from 'react';
import CommodityTop from '../../../components/coupon/commodityTop';
import CommodityList from '../../../components/coupon/commodityList';
import './index.scss';
import list from '../../../config/coupon/commodityList.json';
import BoxSwiper from '../../../components/coupon/boxSwiper';
import hot from '../../../config/coupon/hot.json';
import RightBoxItem from '../../../components/coupon/rightBoxItem';
import shopSort from '../../../config/coupon/shopSort.json';

class CouponCommodity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTopList: list.filter(ele => ele.isTop),
      noTopList: list.filter(ele => !ele.isTop),
      dateIndex: 0,
      sortIndex: 0
    };
  }
  renderSort() { // 排序
    const { sortIndex } = this.state;
    const { sort } = shopSort;
    let html = '';
    html = sort.map((item, i) => (
      <li className={ sortIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'sort')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderDate() { // 类型
    const { dateIndex } = this.state;
    const { date } = shopSort;
    let html = '';
    html = date.map((item, i) => (
      <li className={ dateIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'date')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderFilter() {
    return (
      <div className="tabBar">
        <div className="sort">
          <p>商品分类：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderSort()
              }
            </ul>
          </div>
        </div>
        <div className="sort type">
          <p>发布日期：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderDate()
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
  filter(condition, data) { // 条件过滤
    return data.filter(item => {
      return Object.keys(condition).every(key => {
        if (key) {
          return String(item[key]).toLowerCase().includes(
            String(condition[key]).trim().toLowerCase()
          )
        }
      })
    })
  }
  renderActive(index, type) {
    let data = list;
    this.setState({
      [`${type}Index`]: index
    }, () => {
      const { dateIndex, sortIndex } = this.state;
      const { sort, date } = shopSort;
      let temp;
      temp = {
        sort: ((sort[sortIndex] || {}).type === 'all' ? '' : (sort[sortIndex] || {}).type) || '',
        date: ((date[dateIndex] || {}).type === 'all' ? '' : (date[dateIndex] || {}).type) || ''
      };
      data = this.filter(temp, data);
      this.setState({
        isTopList: data.filter(ele => ele.isTop),
        noTopList: data.filter(ele => !ele.isTop)
      })
    })
  }
  render() {
    const {isTopList, noTopList} = this.state;
    return (
      <div className="all-container col-sm-12">
        <div className="col-sm-2"></div>
        <div className="coupon-commodity-container col-sm-8">
          <div className="coupon-commodity-left">
            { this.renderFilter() }
            <div className="coupon-commodity">
              <CommodityTop top={isTopList}/>
              <CommodityList list={noTopList}/>
            </div>
          </div>
          <div className="coupon-commodity-right">
            <BoxSwiper/>
            <div className="box-list">
              <div className="right-title">
                <i/>
                一周最热网购优惠券
              </div>
              {
                hot.coupon.map((item, index) => (
                  <RightBoxItem key={index} data={item}/>
                ))
              }
            </div>
            <div className="box-list">
              <div className="right-title">
                <i/>
                一周最热值得买
              </div>
              {
                hot.buy.map((item, index) => (
                  <RightBoxItem key={index} data={item}/>
                ))
              }
            </div>
            <div className="box-list">
              <div className="right-title">
                <i/>
                一周最热出行外卖
              </div>
              {
                hot.travel.map((item, index) => (
                  <RightBoxItem key={index} data={item}/>
                ))
              }
            </div>
          </div>
        </div>
        <div className="col-sm-2"></div>
      </div>
    );
  }
  
}

export default CouponCommodity;
