import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';
import store from './store/index';
import { Provider } from 'react-redux';

class App extends Component {
  render() {
    return(
      <Provider store={store}>
        <div className="App">
          {this.props.children}
        </div>
      </Provider>
    )
  }
}

export default withRouter(App);
