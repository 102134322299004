import React, { Component, useEffect } from "react";
import './index.scss';
import Swiper from 'swiper';

function BoxSwiper(props) {
  
  useEffect(() => {
    new Swiper('.right-swiper-container', {
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      autoplay: true
    });
  }, [])
  
  return (
    <section className="right-swiper-container">
      <section className="swiper-wrapper">
        <section className="swiper-slide">
          <img src="/img/coupon/swiper1.jpg" />
          <p>淘宝优惠券+淘宝返利</p>
        </section>
        <section className="swiper-slide">
          <img src="/img/coupon/swiper2.jpg" />
          <p>京东优惠券与京东返利红包！</p>
        </section>
        <section className="swiper-slide">
          <img src="/img/coupon/swiper3.jpg" />
          <p>拼多多优惠券+拼多多返利</p>
        </section>
      </section>
      <div className="swiper-pagination"></div>
    </section>
  )
}

export default BoxSwiper;
