import React from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';
import {Tabs} from 'antd';
import NavigateList from '../../navigate/list';
const { TabPane } = Tabs;

function HotBusiness(props) {
  const { data } = props;
  
  return (
    <div className="hot-business">
      <div className="tab indexTab3">
        <div className="ivu-tabs">
          <Tabs defaultActiveKey="1" tabBarExtraContent={{ left: '热门商家' }}>
            <TabPane key="1">
              <div className="list-tab">
                <ul className="detail-li">
                  {
                    data.map((item, i) => (
                      <NavigateList key={i} data={item}/>
                    ))
                  }
                </ul>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default withRouter(HotBusiness);
