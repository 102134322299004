import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import tagFilter from '../../../config/boutique/tagFilter.json';
import tagDetail from '../../../config/boutique/tagDetail.json';
import TagDetailContent from '../../../components/boutique/tagDetailContent';
import './index.scss';
import information from '../../../config/boutique/information.json';
import ArticlesList from '../../../components/common/articlesList';
import hotBusiness from '../../../config/boutique/hotBusiness.json';
import HotBusiness from '../../../components/common/hotBusiness';
import TagList from '../../../components/common/tagList';

class Tag extends Component {
  constructor(props) {
    super(props);
    const result = new URLSearchParams(this.props.location.search);
    const id = result.get('id');
    this.state = {
      sortIndex: -1,
      typeIndex: -1,
      id,
      detail: this.initDetail(tagDetail.filter(item => {
        return item.id === id
      })[0])
    }
  }
  initDetail(detail) {
    detail.shops.forEach(item => {
      item.type = 'shop';
    })
    detail.coupons.forEach(item => {
      item.type = 'coupon';
    })
    detail.articles.forEach(item => {
      item.type = 'article';
    })
    detail.allList = [...detail.shops, ...detail.coupons, ...detail.articles];
    return detail;
  }
  renderSort() { // 排序
    const { sortIndex } = this.state;
    const { sort } = tagFilter;
    let html = '';
    html = sort.map((item, i) => (
      <li className={ sortIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'sort')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderType() { // 类型
    const { typeIndex } = this.state;
    const { type } = tagFilter;
    let html = '';
    html = type.map((item, i) => (
      <li className={ typeIndex === i ? "ivu-menu-item ivu-menu-item-active" : "ivu-menu-item" } key={i} onClick={this.renderActive.bind(this, i, 'type')}>
        { item.name }
      </li>
    ))
    return html;
  }
  renderFilter(detail) {
    return (
      <div className="tabBar">
        <div className="aboutResult">
          <p>{ detail.name }</p>
        </div>
        {/*<div className="sort">*/}
        {/*  <p>排序：</p>*/}
        {/*  <div className="menus">*/}
        {/*    <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">*/}
        {/*      {*/}
        {/*        this.renderSort()*/}
        {/*      }*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="sort">
          <p>分类：</p>
          <div className="menus">
            <ul className="ivu-menu ivu-menu-light ivu-menu-horizontal">
              {
                this.renderType()
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
  filter(condition, data) { // 条件过滤
    return data.filter(item => {
      return Object.keys(condition).every(key => {
        if (key) {
          return String(item[key]).toLowerCase().includes(
            String(condition[key]).trim().toLowerCase()
          )
        }
      })
    })
  }
  renderActive(index, type) {
    const { detail } = this.state;
    let initDetail = this.initDetail(detail);
    this.setState({
      [`${type}Index`]: index
    }, () => {
      const { typeIndex } = this.state;
      const type1 = tagFilter.type;
      let temp;
      temp =  {
        type: ((type1[typeIndex] || {}).type === 'all' ? '' : (type1[typeIndex] || {}).type) || '',
      };
      initDetail.allList = this.filter(temp, initDetail.allList);
      this.setState({
        detail: initDetail
      })
    })
  }
  componentDidMount() {
    this.props.history.listen(location => {
      const result = new URLSearchParams(location.search);
      const id = result.get('id');
      if (id && location.pathname === '/tagDetail') {
        this.setState({
          typeIndex: -1,
          detail: this.initDetail(tagDetail.filter(item => {
            return item.id === id
          })[0])
        })
      }
    })
  }
  render() {
    const { tag } = information;
    const { detail } = this.state;
    return (
      <div className="tag-detail">
        <div className="left">
          {
            this.renderFilter(detail)
          }
          {
            detail.allList.length ?
              (detail.allList || []).map((item, i) => (
                <TagDetailContent key={i} data={item}/>
              ))
            :
              <div className="none-box">
                <div className="none">
                  <div className="none-item"/>
                  <p>暂无数据</p>
                </div>
              </div>
          }
          <TagList data={tag} title={"热门标签"}/>
        </div>
        <div className="right">
          <ArticlesList key={5} data={information['hot']} title={"相关文章"}/>
          <HotBusiness data={hotBusiness}/>
        </div>
      </div>
    )
  }
}

export default withRouter(Tag);
