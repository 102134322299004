import React, { Component } from 'react';

import part1 from '../../../config/solution/minishop/part1.json'

import './index.scss'

class Minishop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgList: part1.imgList.content,
      data1: part1.data1,
      data2: part1.data2,
      data3: part1.data3,
      isShow: false
    }
  }
  renderImg() {
    const { imgList } = this.state;
    return imgList.map((item, i) => (
      <section key={i} className="col-xs-3 list">
        <section className="each-list" style={{ backgroundImage: `url(${item.img})` }}>
          <div className="mask"></div>
          <div>
            <img src={'img/solution/zhengfangti.png'} />
            <span>{item.title}</span>
          </div>
        </section>
        <p className="caption"><p>{item.title}</p><p>{item.content}</p></p>
      </section>
    ))
  }
  renderData1() {
    const { data1 } = this.state;
    return data1.map((item, i) => (
      <li key={i}>
        <img src={item.img} />
        <span>{item.name}</span>
      </li>
    ))
  }
  renderData2() {
    const { data2 } = this.state;
    return data2.map((item, i) => (
      <div key={i} className="detail col-xs-12">
        <div className="col-xs-1">{item.name}</div>
        <ul className="col-xs-12">
          {
            item.content.map((list, k) => (
              <li className="col-xs-2" key={k}>
                <span>{list}</span>
              </li>
            ))
          }
        </ul>
      </div>
    ))
  }
  renderData3() {
    const { data3 } = this.state;
    return data3.map((item, i) => (
      <li key={i}>
        <img src={item.img} />
        <span>{item.name}</span>
      </li>
    ))
  }
  showZixun() {
    this.setState({
      isShow: true
    })
  }
  toggleShow() {
    this.setState({
      isShow: false
    })
  }
  render() {
    const { isShow } = this.state;
    return (
      <section className="mini-shop">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/solution/banner2.png'} />
          {/* banner图文字 */}
          <div className="content">
            <img className="fade-in-down" src={'img/solution/zhinengshengtai.png'} />
            <p className="p-1 fade-in-up">助力“人-场-货”的门店互联网化、会员营销、</p>
            <p className="p-2 fade-in-up">终端供应的“三位一体”赋能</p>
            <p className="p-3 fade-in-up"><img src={'img/solution/kuang.png'} /><img onClick={this.showZixun.bind(this)} className="zixun" src={'img/solution/lijizixun.png'} /></p>
          </div>
        </section>
        <section className="main container">
          <div className="part-1 container">
            <p className="text-center p-1">{part1.imgList.title.p1}</p>
            <p className="text-center p-2">{part1.imgList.title.p2}</p>
            <div className="col-xs-12">
              {this.renderImg()}
            </div>
          </div>
          <div className="part-2">
            <p className="text-center p-1">新零售生态一体化架构</p>
            <div className="col-xs-12 whole">
              <div className="col-xs-2 left-content">
                <div className="col-xs-12 top">适用对象</div>
                <ul className="col-xs-12 bottom">{this.renderData1()}</ul>
              </div>
              <div className="col-xs-8 center-content">
                <p className="text-center">
                  <img src={'img/solution/xingzhuang.png'} />
                  <span className="s-1">多端功能服务</span>
                  <img src={'img/solution/xingzhuang.png'} />
                </p>
                <p className="line"><span></span></p>
                {this.renderData2()}
              </div>
              <div className="col-xs-2 left-content">
                <div className="col-xs-12 top">应用载体</div>
                <ul className="col-xs-12 bottom">{this.renderData3()}</ul>
              </div>
            </div>
          </div>
        </section>
        {/* 客服热线 */}
        <section className={isShow ? "customer-service" : "fade-out-dialog"} style={{display: isShow ? 'block' : 'none'}}>
          <div className="mask"></div>
          <div className={isShow ? "content fade-in-dialog" : "content"}>
            <p className="p-1">客服热线</p>
            <p className="p-2">020-38915126</p>
            <p className="p-3"><span onClick={this.toggleShow.bind(this)}>继续浏览</span></p>
          </div>
          {/* <img onClick={this.toggleShow.bind(this)} src={'img/solution/guanbi.png'} /> */}
        </section>
      </section>
    )
  }
}

export default Minishop;
