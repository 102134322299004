import React from 'react';
import './index.scss';
import { Button } from 'antd'

function CommodityDetailCom(props) {
    const item = props.detail;
    return ( 
        <div className="commodity-detail-com">
            <div className="title">
            【{item.source}】
                <a href="#">
                    <span className="title-a"> {item.blackTitle}</span>
                    <span className="error-color"> {item.redTitle}</span>
                </a>
            </div>
            <img className="img" src="/img/coupon/commodity/link-sm.jpg" />
            <div className="title-content">
                <p> { item.title }
                    { item.titleRedOne ? <span className="danger-color mg-l10">,{item.titleRedOne}</span>:null }
                </p>
                { 
                    item.message.map((ele,i)=>(<p key={i}>{ele}<br /></p>))
                }
                <div className="image">
                    <a href={item.imageHref} target="_blank">
                        <img className="image-inner" src={item.image}/>
                    </a>
                </div>
                <div className="date-and-author">
                    <div className="date"></div>
                    <div className="date">时间：{item.time} 作者：{item.author}
                        <a href={item.imageHref} target="_blank">
                            <Button className="mg-l40" type="danger">去购买>></Button>
                        </a>
                    </div>
                </div>
                <div className='image mg-t20'>
                    <span>所属商城：{item.source}</span>
                    <span className="mg-l10">关键字：{item.keyWord}</span>
                </div>
            </div>
        </div> 
    )  
}


export default CommodityDetailCom;
