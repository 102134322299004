import React from 'react';
import './index.scss';
import { Button } from 'antd'

function CommodityList(props) {
    return (
        props.list.map((item,index) => (
            <div key={index} className="commodity-list">
                <div className="title">
                【{item.source}】
                    <a href={'#/commodityDetail?id='+item.id}>
                        <span className="title-a"> {item.blackTitle}</span>
                        <span className="error-color"> {item.redTitle}</span>
                    </a>
                </div>
                <img className="img" src="/img/coupon/commodity/link-sm.jpg" />
                <div className="title-content">
                    <p>{item.title}</p> 
                    { 
                        item.message.map((ele,i)=>(<p key={i}>{ele}<br /></p>))
                    }
                    <div className="image">
                        <a href={item.imageHref} target="_blank">
                            <img className="image-inner" src={item.image}/>
                        </a>
                    </div>
                    <div className="date-and-author">
                        <div className="date"></div>
                        <div className="date">时间：{item.time} 作者：{item.author}
                            <a href={item.imageHref} target="_blank">
                                <Button className="mg-l40" type="danger">去购买>></Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )  
}


export default CommodityList;
