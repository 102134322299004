import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import navigateItem from '../../../config/coupon/navigateItem.json';
import './index.scss';

function CouponNavigateItem(props) {
  const { type } = props;
  let dataList = [];
  if (type === 'all') {
    dataList = navigateItem;
  } else {
    dataList = navigateItem.filter(item => {
      return item.type === type;
    })
  }
  const toDetail = (id) => {
    console.log(id);
    props.history.push(`/detailInner?id=${id}`);
  }
  return (
    <div className="navigate-item-container">
      {
        dataList.map((item, index) => (
          <div className="navigate-item" key={index} onClick={()=>toDetail(item.id)}>
            <img src={item.logo}/>
            <span>{item.name}</span>
          </div>
        ))
      }
    </div>
  )
}

export default withRouter(CouponNavigateItem);
