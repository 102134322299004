import React from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';
import {Tabs} from 'antd';
import RightList from '../../boutique/rightList';
const { TabPane } = Tabs;

function ArticlesList(props) {
  const { data, title, tabs } = props;
  
  return (
    <div className="article-all-list">
      <div className="tab indexTab3">
        <div className="ivu-tabs">
          <Tabs defaultActiveKey="1" tabBarExtraContent={{ left: title }}>
            {
              !(tabs || []).length ?
                <TabPane>
                  <div className="article-list">
                    <ul>
                      {
                        data.map((item, i) => (
                          <li key={Math.random() * 100}>
                            <RightList data={item} key={i * 100} i={i}/>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </TabPane>
              :
              tabs.map((tab, index) => (
                <TabPane tab={tab.name} key={index + 1}>
                  <div className="article-list">
                    <ul>
                      {
                        tab.list.map((item, i) => (
                          <li key={Math.random() * 100}>
                            <RightList data={item} key={`tab${i}`} i={i}/>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </TabPane>
              ))
            }
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ArticlesList);
