import React, { Component } from "react";
import { Tabs } from 'antd';
import CouponNavigate from '../../../components/coupon/navigate';
import CouponBoxItem from '../../../components/coupon/boxItem';
import RightBoxItem from '../../../components/coupon/rightBoxItem';
import BoxSwiper from '../../../components/coupon/boxSwiper';
import boxItem from '../../../config/coupon/boxItem.json';
import hot from '../../../config/coupon/hot.json';
import './index.scss';

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className="col-sm-12 all-container">
        <div className="col-sm-2"></div>
        <div className="coupon-container col-sm-8">
          <div className="navigate">
            <CouponNavigate/>
          </div>
          <div className="discount">
            <div className="title">
              <h4>每日优惠精选</h4>
            </div>
            <div className="new-box">
              <div className="clear">
                <div className="box-left">
                  {
                    boxItem.map((item, index) => (
                      <CouponBoxItem key={index} data={item}/>
                    ))
                  }
                </div>
                <div className="box-right">
                  <BoxSwiper/>
                  <div className="box-list">
                    <div className="right-title">
                      <i/>
                      一周最热网购优惠券
                    </div>
                    {
                      hot.coupon.map((item, index) => (
                        <RightBoxItem key={index} data={item}/>
                      ))
                    }
                  </div>
                  <div className="box-list">
                    <div className="right-title">
                      <i/>
                      一周最热值得买
                    </div>
                    {
                      hot.buy.map((item, index) => (
                        <RightBoxItem key={index} data={item}/>
                      ))
                    }
                  </div>
                  <div className="box-list">
                    <div className="right-title">
                      <i/>
                      一周最热出行外卖
                    </div>
                    {
                      hot.travel.map((item, index) => (
                        <RightBoxItem key={index} data={item}/>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2"></div>
      </div>
    )
  }
}

export default Coupon;
