import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import hotBusiness from '../../../config/boutique/hotBusiness.json';
import ArticleLeft from '../../../components/navigate/article';
import HotBusiness from '../../../components/common/hotBusiness';
import articles from '../../../config/navigate/articles.json';
import information from '../../../config/boutique/information.json';
import ArticlesList from '../../../components/common/articlesList';

class ArticleDetail extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const result = new URLSearchParams(this.props.location.search);
    const id = result.get('id');
    const detail = articles.filter(item => {
      return item.id === id;
    })
    return (
      <div className="article-detail">
        <div className="left">
          <ArticleLeft data={detail[0]}/>
          <ArticlesList key={6} data={information['hot']} title={"相关文章"}/>
        </div>
        <div className="right">
          <HotBusiness data={hotBusiness}/>
        </div>
      </div>
    )
  }
}

export default withRouter(ArticleDetail);
