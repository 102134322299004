import React, { Component } from "react";
import './index.scss';

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false
    }
  }
  render() {
    return (
      <section className="contact">
        {/* banner图 */}
        <section className="banner">
          <img className="col-xs-12" src={'img/more/banner2.png'} />
          {/* banner图文字 */}
          <div className="content">
            <p className="p-3 fade-in-left"></p>
            <p className="p-1 fade-in-left">每一个实体案例都是我们倾心打造！</p>
            <p className="p-2 fade-in-left">联系我们，为你量身定做</p>
            {/* <form className="form-horizontal fade-in-left">
              <div className="form-group">
                <label htmlFor="name" className="col-xs-2 control-label">你的姓名</label>
                <div className="col-xs-4">
                  <input className="form-control" id="name" />
                </div>
                <label htmlFor="scale" className="col-xs-2 control-label">公司规模</label>
                <div className="col-xs-4">
                  <input className="form-control" id="scale" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="phone" className="col-xs-2 control-label">电话</label>
                <div className="col-xs-4">
                  <input className="form-control" id="phone" />
                </div>
                <label htmlFor="product" className="col-xs-2 control-label">关注产品</label>
                <div className="col-xs-4">
                  <input className="form-control" id="product" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email" className="col-xs-2 control-label">邮箱</label>
                <div className="col-xs-4">
                  <input className="form-control" id="email" />
                </div>
                <label htmlFor="company" className="col-xs-2 control-label">公司</label>
                <div className="col-xs-4">
                  <input className="form-control" id="company" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message" className="col-xs-2 control-label">留言</label>
                <div className="col-xs-4">
                  <textarea id="message" className="form-control" rows="3"></textarea>
                </div>
              </div>
              <p>
                <button type="button" className="btn btn-danger">提交</button>
              </p>
            </form> */}
          </div>
        </section>
        <section className="main container">
          <div className="part-1 container">
            <p className="p-1">公司地址</p>
            <p className="p-2">company address</p>
            <p className="p-line"></p>
            <div className="address">
              <img src={'img/more/banner3.png'} />
              <div>
                <p>广州总部</p>
                <p><span>联系电话：38915126</span><span>邮编：510700</span></p>
                <p className="p-3">通讯地址：广东省广州市天河区林和西路157号保利中汇A座505 </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

export default Contact;
